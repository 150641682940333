import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const DatabaseConnectIcon = createSvgIcon(
 <svg
  height="800px"
  width="800px"
  version="1.1"
  id="_x32_"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 512 512"
  xmlSpace="preserve">
  <g>
   <path
    fill="currentColor"
    d="M507.34,98.426c-5.269-12.142-14.39-22.598-25.948-31.612c-17.387-13.51-40.586-24.054-67.719-31.464
       c-27.134-7.384-58.235-11.534-91.338-11.534c-50.447,0.033-96.203,9.566-130.465,25.734c-17.132,8.126-31.465,17.922-42.002,29.67
       c-5.252,5.877-9.541,12.282-12.545,19.206c-2.997,6.924-4.668,14.39-4.66,22.03v30.08l26.162,19.856v-4.346
       c15.132,13.838,36.379,24.877,61.792,33.052c29.299,9.36,64.204,14.736,101.718,14.736c50.028-0.024,95.371-9.508,129.058-25.421
       c13.55-6.421,25.126-13.93,34.444-22.482v58.845c-1.86,3.992-4.511,7.993-8.1,12.027c-11.591,13.056-32.583,25.355-59.652,33.958
       c-27.067,8.661-60.12,13.814-95.75,13.806c-9.278,0.008-18.3-0.444-27.166-1.111l-22.886,17.37
       c15.946,2.166,32.698,3.368,50.052,3.368c50.028-0.025,95.371-9.516,129.058-25.422c13.55-6.421,25.126-13.928,34.444-22.482
       v58.845c-1.86,3.992-4.511,7.994-8.1,12.035c-11.591,13.057-32.583,25.348-59.652,33.959c-27.067,8.66-60.12,13.813-95.75,13.806
       c-46.232,0.025-88.061-8.751-118.125-22.441l-17.551,13.319c10.184,5.343,21.569,10.019,33.958,14.004
       c29.299,9.36,64.204,14.735,101.718,14.744c50.028-0.024,95.371-9.516,129.058-25.421c13.55-6.422,25.126-13.938,34.444-22.491
       v44.891c0,3.894-0.806,7.689-2.502,11.648c-2.964,6.891-8.941,14.3-18.013,21.355c-13.583,10.611-33.9,20.186-58.531,26.87
       c-24.623,6.701-53.552,10.62-84.455,10.612c-47.08,0.041-89.625-9.146-119.269-23.224c-14.835-6.997-26.36-15.213-33.687-23.454
       c-3.688-4.116-6.339-8.216-8.043-12.159c-1.704-3.96-2.503-7.754-2.511-11.648v-4.602l-24.276,18.423
       c0.741,2.8,1.63,5.557,2.774,8.208c5.276,12.142,14.39,22.597,25.948,31.62c17.395,13.51,40.593,24.055,67.727,31.464
       c27.133,7.384,58.235,11.525,91.337,11.533c50.456-0.041,96.195-9.565,130.457-25.742c17.14-8.124,31.464-17.913,42.001-29.669
       c5.253-5.87,9.55-12.275,12.546-19.206c2.997-6.915,4.676-14.391,4.66-22.03V120.456
       C512.016,112.816,510.337,105.349,507.34,98.426z M485.837,134.409c-1.86,3.993-4.511,8.002-8.1,12.036
       c-11.591,13.064-32.583,25.355-59.652,33.966c-27.067,8.66-60.12,13.813-95.75,13.806c-47.5,0.024-90.448-9.196-120.669-23.536
       c-15.123-7.146-26.994-15.552-34.732-24.236c-3.59-4.034-6.249-8.043-8.11-12.028v-13.962c0.008-3.895,0.807-7.681,2.511-11.641
       c2.964-6.899,8.932-14.3,18.012-21.363c13.576-10.611,33.892-20.185,58.524-26.862c24.631-6.709,53.559-10.62,84.463-10.612
       c47.081-0.041,89.625,9.146,119.262,23.224c14.835,6.989,26.359,15.205,33.686,23.454c3.688,4.116,6.338,8.208,8.052,12.159
       c1.695,3.96,2.502,7.746,2.502,11.641V134.409z"
   />
   <polygon
    fill="currentColor"
    points="238.572,278.663 88.242,164.564 88.242,224.207 0,224.207 0,333.12 88.242,333.12 88.242,392.763 	"
   />
  </g>
 </svg>,
 "DatabaseConnect"
);
