import React, { ReactElement } from "react";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AlbumIcon from "@mui/icons-material/Album";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BalanceIcon from "@mui/icons-material/Balance";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BuildIcon from "@mui/icons-material/Build";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ComputerIcon from "@mui/icons-material/Computer";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DangerousIcon from "@mui/icons-material/Dangerous";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DnsIcon from "@mui/icons-material/Dns";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DownloadIcon from "@mui/icons-material/Download";
import DvrIcon from "@mui/icons-material/Dvr";
import ErrorIcon from "@mui/icons-material/Error";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FolderIcon from "@mui/icons-material/Folder";
import ForumIcon from "@mui/icons-material/Forum";
import GppBadIcon from "@mui/icons-material/GppBad";
import GridViewIcon from "@mui/icons-material/GridView";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HideSourceIcon from "@mui/icons-material/HideSource";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import LanIcon from "@mui/icons-material/Lan";
import LanguageIcon from "@mui/icons-material/Language";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockResetIcon from "@mui/icons-material/LockReset";
import MemoryIcon from "@mui/icons-material/Memory";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import MoneyIcon from "@mui/icons-material/Money";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PasswordIcon from "@mui/icons-material/Password";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PhpIcon from "@mui/icons-material/Php";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ReplayIcon from "@mui/icons-material/Replay";
import ReplyIcon from "@mui/icons-material/Reply";
import ReportIcon from "@mui/icons-material/Report";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import RestoreIcon from "@mui/icons-material/Restore";
import RouterIcon from "@mui/icons-material/Router";
import SendIcon from "@mui/icons-material/Send";
import SensorsIcon from "@mui/icons-material/Sensors";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import SettingsSystemDaydreamIcon from "@mui/icons-material/SettingsSystemDaydream";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import StorageIcon from "@mui/icons-material/Storage";
import SyncIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import TaskIcon from "@mui/icons-material/Task";
import TerminalIcon from "@mui/icons-material/Terminal";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TuneIcon from "@mui/icons-material/Tune";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WarningIcon from "@mui/icons-material/Warning";
import WebIcon from "@mui/icons-material/Web";

import { SvgIconProps } from "@mui/material";
import Stack from "@mui/material/Stack";

import { AddonsIcon } from "./svgs/Addons";
import { AppIcon } from "./svgs/App";
import { BackupIcon } from "./svgs/Backup";
import { BellIcon } from "./svgs/Bell";
import { ClientIcon } from "./svgs/Client";
import { ClipboardIcon } from "./svgs/Clipboard";
import { CloudboxIcon } from "./svgs/Cloudbox";
import { CreditsIcon } from "./svgs/Credits";
import { CronjobIcon } from "./svgs/Cronjob";
import { DataIcon } from "./svgs/Data";
import { DatabaseIcon } from "./svgs/Database";
import { DatabaseConnectIcon } from "./svgs/DatabaseConnect";
import { DatabaseManagementIcon } from "./svgs/DatabaseManagement";
import { DeleteIcon } from "./svgs/Delete";
import { DetailsIcon } from "./svgs/Details";
import { DiscIcon } from "./svgs/Disc";
import { DockerIcon } from "./svgs/Docker";
import { DomainIcon } from "./svgs/Domain";
import { EditIcon } from "./svgs/Edit";
import { FirewallIcon } from "./svgs/Firewall";
import { FloatingipIcon } from "./svgs/Floatingip";
import { FtpIcon } from "./svgs/Ftp";
import { HostIcon } from "./svgs/Host";
import { InboundIcon } from "./svgs/Inbound";
import { IsoIcon } from "./svgs/Iso";
import { KeyIcon } from "./svgs/Key";
import { LetsEncryptIcon } from "./svgs/LetsEncrypt";
import { LockedIcon } from "./svgs/Locked";
import { LogsIcon } from "./svgs/Logs";
import { MailIcon } from "./svgs/Mail";
import { MenuIcon } from "./svgs/Menu";
import { NetworkIcon } from "./svgs/Network";
import { OdooIcon } from "./svgs/Odoo";
import { OrdersIcon } from "./svgs/Orders";
import { OutboundIcon } from "./svgs/Outbound";
import { PhpMyAdminIcon } from "./svgs/PhpMyAdmin";
import { PowerIcon } from "./svgs/Power";
import { ProfileIcon } from "./svgs/Profile";
import { RamIcon } from "./svgs/Ram";
import { RebootIcon } from "./svgs/Reboot";
import { RebuildIcon } from "./svgs/Rebuild";
import { RechargeIcon } from "./svgs/Recharge";
import { RouteIcon } from "./svgs/Route";
import { ScheduleIcon } from "./svgs/Schedule";
import { ServerIcon } from "./svgs/Server";
import { ServiceIcon } from "./svgs/Service";
import { SitesIcon } from "./svgs/Sites";
import { SnapshotIcon } from "./svgs/Snapshot";
import { SslIcon } from "./svgs/Ssl";
import { StatusIcon } from "./svgs/Status";
import { SubnetIcon } from "./svgs/Subnet";
import { TeamsIcon } from "./svgs/Teams";
import { TomcatIcon } from "./svgs/Tomcat";
import { UnlockedIcon } from "./svgs/Unlocked";
import { UpgradeIcon } from "./svgs/Upgrade";
import { VolumeIcon } from "./svgs/Volume";
import { ZoneIcon } from "./svgs/Zone";

type Props = {
 icon?: string;
 props?: SvgIconProps;
};

const IconSelector = ({ icon, props }: Props): ReactElement => {
 const iconSelection = (): ReactElement => {
  switch (icon) {
   case "AlternateEmailIcon":
    return <AlternateEmailIcon {...props} />;
   case "BorderColorIcon":
    return <BorderColorIcon {...props} />;
   case "ContentCopyIcon":
    return <ContentCopyIcon {...props} />;
   case "ComputerIcon":
    return <ComputerIcon {...props} />;
   case "InsertLinkIcon":
    return <InsertLinkIcon {...props} />;
   case "LanguageIcon":
    return <LanguageIcon {...props} />;
   case "DnsIcon":
    return <DnsIcon {...props} />;
   case "PersonIcon":
    return <PersonIcon {...props} />;
   case "PeopleIcon":
    return <PeopleIcon {...props} />;
   case "MoneyIcon":
    return <MoneyIcon {...props} />;
   case "CurrencyExchangeIcon":
    return <CurrencyExchangeIcon {...props} />;
   case "KeyIcon":
    return <KeyIcon {...props} />;
   case "LockResetIcon":
    return <LockResetIcon {...props} />;
   case "GroupAddIcon":
    return <GroupAddIcon {...props} />;
   case "MoreVertIcon":
    return <MoreVertIcon {...props} />;
   case "LockOutlinedIcon":
    return <LockOutlinedIcon {...props} />;
   case "LockOpenOutlinedIcon":
    return <LockOpenOutlinedIcon {...props} />;
   case "LanIcon":
    return <LanIcon {...props} />;
   case "SettingsInputAntennaIcon":
    return <SettingsInputAntennaIcon {...props} />;
   case "CameraAltIcon":
    return <CameraAltIcon {...props} />;
   case "SettingsSystemDaydreamIcon":
    return <SettingsSystemDaydreamIcon {...props} />;
   case "StorageIcon":
    return <StorageIcon {...props} />;
   case "LocalFireDepartmentIcon":
    return <LocalFireDepartmentIcon {...props} />;
   case "BackupIcon":
    return <BackupIcon {...props} />;
   case "UpgradeIcon":
    return <UpgradeIcon {...props} />;
   case "BuildIcon":
    return <BuildIcon {...props} />;
   case "WebIcon":
    return <WebIcon {...props} />;
   case "CheckCircleIcon":
    return <CheckCircleIcon {...props} />;
   case "ReportIcon":
    return <ReportIcon {...props} />;
   case "MiscellaneousServicesIcon":
    return <MiscellaneousServicesIcon {...props} />;
   case "PriceCheckIcon":
    return <PriceCheckIcon {...props} />;
   case "AlbumIcon":
    return <AlbumIcon {...props} />;
   case "WarningIcon":
    return <WarningIcon {...props} />;
   case "SettingsApplicationsIcon":
    return <SettingsApplicationsIcon {...props} />;
   case "AltRouteIcon":
    return <AltRouteIcon {...props} />;
   case "SensorsIcon":
    return <SensorsIcon {...props} />;
   case "LinkOffIcon":
    return <LinkOffIcon {...props} />;
   case "AddIcon":
    return <AddIcon {...props} />;
   case "VerticalAlignTopIcon":
    return <VerticalAlignTopIcon {...props} />;
   case "VerticalAlignBottomIcon":
    return <VerticalAlignBottomIcon {...props} />;
   case "ServerIcon":
    return <ServerIcon {...props} />;
   case "AppIcon":
    return <AppIcon {...props} />;
   case "AddonsIcon":
    return <AddonsIcon {...props} />;
   case "DomainIcon":
    return <DomainIcon {...props} />;
   case "ProfileIcon":
    return <ProfileIcon {...props} />;
   case "ClientIcon":
    return <ClientIcon {...props} />;
   case "TeamsIcon":
    return <TeamsIcon {...props} />;
   case "RechargeIcon":
    return <RechargeIcon {...props} />;
   case "HostIcon":
    return <HostIcon {...props} />;
   case "ServiceIcon":
    return <ServiceIcon {...props} />;
   case "CreditsIcon":
    return <CreditsIcon {...props} />;
   case "OrdersIcon":
    return <OrdersIcon {...props} />;
   case "DetailsIcon":
    return <DetailsIcon {...props} />;
   case "RebuildIcon":
    return <RebuildIcon {...props} />;
   case "SitesIcon":
    return <SitesIcon {...props} />;
   case "MailIcon":
    return <MailIcon {...props} />;
   case "ZoneIcon":
    return <ZoneIcon {...props} />;
   case "CloudboxIcon":
    return <CloudboxIcon {...props} />;
   case "NetworkIcon":
    return <NetworkIcon {...props} />;
   case "SnapshotIcon":
    return <SnapshotIcon {...props} />;
   case "VolumeIcon":
    return <VolumeIcon {...props} />;
   case "FirewallIcon":
    return <FirewallIcon {...props} />;
   case "CronjobIcon":
    return <CronjobIcon {...props} />;
   case "InboundIcon":
    return <InboundIcon {...props} />;
   case "OutboundIcon":
    return <OutboundIcon {...props} />;
   case "SubnetIcon":
    return <SubnetIcon {...props} />;
   case "RouteIcon":
    return <RouteIcon {...props} />;
   case "FloatingipIcon":
    return <FloatingipIcon {...props} />;
   case "BellIcon":
    return <BellIcon {...props} />;
   case "StatusIcon":
    return <StatusIcon {...props} />;
   case "ScheduleIcon":
    return <ScheduleIcon {...props} />;
   case "ClipboardIcon":
    return <ClipboardIcon {...props} />;
   case "IsoIcon":
    return <IsoIcon {...props} />;
   case "RebootIcon":
    return <RebootIcon {...props} />;
   case "PowerIcon":
    return <PowerIcon {...props} />;
   case "MenuIcon":
    return <MenuIcon {...props} />;
   case "EditIcon":
    return <EditIcon {...props} />;
   case "SslIcon":
    return <SslIcon {...props} />;
   case "DeleteIcon":
    return <DeleteIcon {...props} />;
   case "LockedIcon":
    return <LockedIcon {...props} />;
   case "UnlockedIcon":
    return <UnlockedIcon {...props} />;
   case "DataIcon":
    return <DataIcon {...props} />;
   case "LogsIcon":
    return <LogsIcon {...props} />;
   case "RamIcon":
    return <RamIcon {...props} />;
   case "DiscIcon":
    return <DiscIcon {...props} />;
   case "CpuIcon":
    return <MemoryIcon {...props} />;
   case "RouterIcon":
    return <RouterIcon {...props} />;
   case "HelpOutlineIcon":
    return <HelpOutlineIcon {...props} />;
   case "KeyboardArrowUpIcon":
    return <KeyboardArrowUpIcon {...props} />;
   case "KeyboardArrowDownIcon":
    return <KeyboardArrowDownIcon {...props} />;
   case "ReplyIcon":
    return <ReplyIcon {...props} />;
   case "HighlightOffIcon":
    return <HighlightOffIcon {...props} />;
   case "DoNotDisturbIcon":
    return <DoNotDisturbIcon {...props} />;
   case "ReportProblemIcon":
    return <ReportProblemIcon {...props} />;
   case "ErrorIcon":
    return <ErrorIcon {...props} />;
   case "DataSaverOnIcon":
    return <DataSaverOnIcon {...props} />;
   case "GppBadIcon":
    return <GppBadIcon {...props} />;
   case "BalanceIcon":
    return <BalanceIcon {...props} />;
   case "CardMembershipIcon":
    return <CardMembershipIcon {...props} />;
   case "TrackChangesIcon":
    return <TrackChangesIcon {...props} />;
   case "DockerIcon":
    return <DockerIcon {...props} />;
   case "MoveUpIcon":
    return <MoveUpIcon {...props} />;
   case "VisibilityIcon":
    return <VisibilityIcon {...props} />;
   case "VisibilityOffIcon":
    return <VisibilityOffIcon {...props} />;
   case "FolderIcon":
    return <FolderIcon {...props} />;
   case "ListAltIcon":
    return <ListAltIcon {...props} />;
   case "PhpMyAdminIcon":
    return <PhpMyAdminIcon {...props} />;
   case "DatabaseIcon":
    return <DatabaseIcon {...props} />;
   case "ArrowBackIosIcon":
    return <ArrowBackIosIcon {...props} />;
   case "CloseIcon":
    return <CloseIcon {...props} />;
   case "FtpIcon":
    return <FtpIcon {...props} />;
   case "ArrowOutwardIcon":
    return <ArrowOutwardIcon {...props} />;
   case "TrendingUpIcon":
    return <TrendingUpIcon {...props} />;
   case "TrendingDownIcon":
    return <TrendingDownIcon {...props} />;
   case "ArrowForwardIosIcon":
    return <ArrowForwardIosIcon {...props} />;
   case "PersonOffIcon":
    return <PersonOffIcon {...props} />;
   case "PhpIcon":
    return <PhpIcon {...props} />;
   case "QueryStatsIcon":
    return <QueryStatsIcon {...props} />;
   case "ReplayIcon":
    return <ReplayIcon {...props} />;
   case "TuneIcon":
    return <TuneIcon {...props} />;
   case "InfoIcon":
    return <InfoIcon {...props} />;
   case "CancelIcon":
    return <CancelIcon {...props} />;
   case "AutoAwesomeIcon":
    return <AutoAwesomeIcon {...props} />;
   case "AllInclusiveIcon":
    return <AllInclusiveIcon {...props} />;
   case "LibraryBooksIcon":
    return <LibraryBooksIcon {...props} />;
   case "HideSourceIcon":
    return <HideSourceIcon {...props} />;
   case "KeyOffIcon":
    return <KeyOffIcon {...props} />;
   case "DeleteSweepIcon":
    return <DeleteSweepIcon {...props} />;
   case "TerminalIcon":
    return <TerminalIcon {...props} />;
   case "RestoreIcon":
    return <RestoreIcon {...props} />;
   case "SyncDisabledIcon":
    return <SyncDisabledIcon {...props} />;
   case "SyncIcon":
    return <SyncIcon {...props} />;
   case "FileDownloadIcon":
    return <FileDownloadIcon {...props} />;
   case "LetsEncryptIcon":
    return <LetsEncryptIcon {...props} />;
   case "LockPersonIcon":
    return <LockPersonIcon {...props} />;
   case "DownloadIcon":
    return <DownloadIcon {...props} />;
   case "AssignmentReturnedIcon":
    return <AssignmentReturnedIcon {...props} />;
   case "Inventory2Icon":
    return <Inventory2Icon {...props} />;
   case "DvrIcon":
    return <DvrIcon {...props} />;
   case "OpenInNewIcon":
    return <OpenInNewIcon {...props} />;
   case "HomeIcon":
    return <HomeIcon {...props} />;
   case "ForumIcon":
    return <ForumIcon {...props} />;
   case "ConfirmationNumberIcon":
    return <ConfirmationNumberIcon {...props} />;
   case "SendIcon":
    return <SendIcon {...props} />;
   case "SpeakerNotesIcon":
    return <SpeakerNotesIcon {...props} />;
   case "AssuredWorkloadIcon":
    return <AssuredWorkloadIcon {...props} />;
   case "AccountBalanceIcon":
    return <AccountBalanceIcon {...props} />;
   case "AddCircleIcon":
    return <AddCircleIcon {...props} />;
   case "RemoveCircleIcon":
    return <RemoveCircleIcon {...props} />;
   case "FileUploadIcon":
    return <FileUploadIcon {...props} />;
   case "TaskIcon":
    return <TaskIcon {...props} />;
   case "InsertDriveFileIcon":
    return <InsertDriveFileIcon {...props} />;
   case "DangerousIcon":
    return <DangerousIcon {...props} />;
   case "CreditCardIcon":
    return <CreditCardIcon {...props} />;
   case "DatabaseManagementIcon":
    return <DatabaseManagementIcon {...props} />;
   case "TomcatIcon":
    return <TomcatIcon {...props} />;
   case "OdooIcon":
    return <OdooIcon {...props} />;
   case "DatabaseConnectIcon":
    return <DatabaseConnectIcon {...props} />;
   case "PasswordIcon":
    return <PasswordIcon {...props} />;
   default:
    return <GridViewIcon {...props} />;
  }
 };

 return <Stack component="span">{iconSelection()}</Stack>;
};

export default IconSelector;
