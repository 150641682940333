/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";
import UpgradeBox from "components/shared/upgradeBox/UpgradeBox";

import {
 postRetryDeprecatedServer,
 postServerPossibleUpgrades
} from "redux/handlers/serverHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { UpgradeTypesAPI } from "types/api/serversApiInterface";
import { serverOrdersTableData } from "types/global/table";

const RetryDeprecatedOrder = ({
 id,
 order
}: {
 id: number;
 order: serverOrdersTableData;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const userBalance = useSelector(getUserBalance);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [tabValue, setTabValue] = useState<number>(0);
 const [selectedUpgrade, setSelectedUpgrade] = useState<UpgradeTypesAPI>();
 const [possibleUpgrades, setPossibleUpgrades] = useState<Array<UpgradeTypesAPI>>([]);

 const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setTabValue(newValue);
 };

 const handleOpen = async () => {
  await handleGetUpgrades();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postRetryDeprecatedServer(id, selectedUpgrade?.name || "", selectedUpgrade?.price || 0)
  );
  setLoading(false);
  handleClose();
 };

 const handleGetUpgrades = async () => {
  if (id) setPossibleUpgrades(await dispatch(postServerPossibleUpgrades(order.server_id_db || 0)));
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <Typography>
     <FormattedMessage id={"server.retryOrder"} />
    </Typography>
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.retryOrder" })}
    handleClose={handleClose}
    disabled={loading || !selectedUpgrade || selectedUpgrade?.price > userBalance}
    handleConfirm={handleConfirm}>
    <Stack spacing={2}>
     <Alert severity="info">
      <FormattedMessage id="server.retryDeprecatedMessage" />
     </Alert>
     <Stack>
      <Box sx={{ width: "100%", typography: "body1" }}>
       <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
         <Tab label="Standard" />
         <Tab label="Dedicated" />
        </Tabs>
       </Box>
       {tabValue === 0 ? (
        <Stack spacing={1}>
         {possibleUpgrades
          .filter((element) => !element.name.includes("VFC"))
          .map((element, index) => {
           return (
            <UpgradeBox
             key={`upgrade-element-${index}`}
             showTag={false}
             upgradeElement={element}
             showTag2={false}
             selected={element.id === selectedUpgrade?.id}
             serverPrice={Number(order.amount) || 0}
             selectUpgrade={(value) => setSelectedUpgrade(value)}
            />
           );
          })}
        </Stack>
       ) : (
        <Stack spacing={1}>
         {possibleUpgrades
          .filter((element) => element.name.includes("VFC"))
          .map((element, index) => {
           return (
            <UpgradeBox
             key={`upgrade-element-${index}`}
             showTag={false}
             upgradeElement={element}
             showTag2={false}
             selected={element.id === selectedUpgrade?.id}
             serverPrice={Number(order.amount) || 0}
             selectUpgrade={(value) => setSelectedUpgrade(value)}
            />
           );
          })}
        </Stack>
       )}
      </Box>
     </Stack>
     <Divider />
     <Stack>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.balance" />
       </Typography>
       <Typography>
        <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.totalPrice" />
       </Typography>
       <Typography>
        <FormattedNumber value={selectedUpgrade?.price || 0} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
      <Stack>
       <Typography>
        <FormattedMessage id="server.retryDeprecatedConfirm" />
       </Typography>
      </Stack>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default RetryDeprecatedOrder;
