import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { formatDateColor } from "helpers/app";

import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import FirewallActions from "components/addons/firewall/FirewallActions";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import IconSelector from "components/shared/images/IconSelector";

import { firewallsTableData } from "types/global/table";

const FirewallTable = ({
 rowData,
 index
}: {
 rowData: firewallsTableData;
 index: number;
}): ReactElement => {
 const navigate = useNavigate();

 const RenderStatus = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
   default:
    return (
     <IconSelector
      icon="ReportProblemIcon"
      props={{ style: { color: "red" }, fontSize: "small" }}
     />
    );
  }
 };

 const handleNavigate = (id: number) => {
  rowData.status === "active" &&
   navigate(`/addons/firewall/manage/${id}/details`, { state: { firewall: rowData.name } });
 };

 return (
  <TableRow
   key={`firewall-list-${index}`}
   onClick={() => handleNavigate(rowData.id)}
   sx={{ cursor: "pointer" }}>
   <TableCell component="th" scope="row">
    <Stack direction="column">
     <Stack direction="row" alignItems="center" spacing={1}>
      <RenderStatus status={rowData.status} />
      <Typography variant="inherit" fontWeight="bold">
       {rowData.name}
      </Typography>
     </Stack>
     <Stack onClick={(event) => event.stopPropagation()} maxWidth="fit-content">
      <AdminLogAsUser userid={rowData.userid || ""} owner={rowData.personal_name || ""} />
     </Stack>
    </Stack>
   </TableCell>
   <TableCell>
    <Typography variant="inherit">
     <FormattedMessage id="addons.firewall.insertedRules" />: {rowData.rules.length}
    </Typography>
   </TableCell>
   <TableCell>
    <Stack>
     <Typography variant="caption" color={formatDateColor(rowData.status)}>
      <FormattedDate value={rowData.expire} />
     </Typography>
     {rowData.status === "expired" && (
      <Typography variant="caption" color="error">
       {rowData.status}
      </Typography>
     )}
    </Stack>
   </TableCell>
   <TableCell onClick={(e) => e.stopPropagation()}>
    <FirewallActions rowData={rowData} />
   </TableCell>
  </TableRow>
 );
};

export default FirewallTable;
