import React, { ReactElement, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { generatePassword } from "redux/handlers/userHandler";

import IconSelector from "../images/IconSelector";

const ResetPasswordComponent = ({
 control,
 error,
 setPassword
}: {
 control: Control<{ password: string }>;
 error: boolean;
 setPassword: (password: string) => void;
}): ReactElement => {
 const intl = useIntl();

 const [showPassword, setShowPassword] = useState<string>("password");

 const handleGeneratePassword = async () => {
  setPassword(await generatePassword());
 };

 return (
  <Stack direction="row" spacing={2} alignItems="center">
   <Controller
    name="password"
    control={control}
    rules={{
     required: true,
     minLength: 8
    }}
    render={({ field }) => (
     <TextField
      {...field}
      fullWidth={true}
      label={intl.formatMessage({ id: "mails.mailboxPassword" })}
      error={error}
      InputLabelProps={{ shrink: true }}
      sx={{ my: 2 }}
      type={showPassword}
      autoComplete="new-password"
      helperText={error && intl.formatMessage({ id: "mails.mailboxPasswordError" })}
      InputProps={{
       endAdornment: (
        <InputAdornment position="end">
         <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
           setShowPassword(showPassword === "text" ? "password" : "text");
          }}
          edge="end">
          {showPassword ? (
           <IconSelector icon="VisibilityOffIcon" />
          ) : (
           <IconSelector icon="VisibilityIcon" />
          )}
         </IconButton>
        </InputAdornment>
       )
      }}
     />
    )}
   />
   <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
    <FormattedMessage id="app.generatePassword" />
   </Button>
  </Stack>
 );
};

export default ResetPasswordComponent;
