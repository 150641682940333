import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postRestoreBackup } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RestoreFromBackup = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [deleteData, setDeleteData] = useState<boolean>(false);

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRestoreBackup(id, deleteData));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.restoreBackup" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="RestoreIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.restoreBackup" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack>
     <Typography>
      <FormattedMessage id="docker.website.restoreBackupMessage" />
     </Typography>
     <FormControlLabel
      control={
       <Checkbox
        checked={deleteData}
        onChange={(e, checked) => {
         setDeleteData(checked);
        }}
       />
      }
      label={intl.formatMessage({ id: "docker.website.deleteDataAfterRestore" })}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default RestoreFromBackup;
