import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postRevokeSslCertificate } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RevokeSslCertificate = ({ id, sslOn }: { id: number; sslOn: boolean }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRevokeSslCertificate(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.deleteSslCertificate" />}>
    <IconButton disabled={!sslOn} onClick={() => handleOpen()}>
     <IconSelector icon="KeyOffIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.deleteSslCertificate" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack spacing={2}>
     <Alert severity="warning">
      <Typography variant="subtitle2">
       <FormattedMessage id="docker.website.deleteSslCertMessage" />
      </Typography>
     </Alert>
     <Typography>
      <FormattedMessage id="docker.website.deleteSslCertMessage2" />
     </Typography>
    </Stack>
   </AppModal>
  </>
 );
};

export default RevokeSslCertificate;
