/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import DvrIcon from "@mui/icons-material/Dvr";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { steps } from "constants/backup";
import { fqdnRegex } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { getServerBackupsType, postCreateServerFromBackup } from "redux/handlers/backupsHandler";
import { getServersLocations, postCheckServerName } from "redux/handlers/serverHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerFromBackupData } from "types/api/backupApiInterface";
import { ILocations } from "types/api/serversApiInterface";

const ServerFromBackupWizard = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const params = useParams<{ id: string }>();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const userBalance = useSelector(getUserBalance);

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [newServerName, setNewServerName] = useState<string>("");
 const [locationList, setLocationList] = useState<Array<ILocations>>([]);
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [nameChecked, setNameChecked] = useState<string>("");
 const [newServerData, setNewServerData] = useState<IServerFromBackupData | null>(null);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setLocationList(await dispatch(getServersLocations(1)));
   setNewServerData(await dispatch(getServerBackupsType(Number(params.id))));
   setIsLoading(false);
  })();
 }, []);

 const checkServerName = async () => {
  setStepLoading(true);
  setNameChecked(await dispatch(postCheckServerName(newServerName)));
  setStepLoading(false);
 };

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  setNewServerName("");
  setActiveStep(0);
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateServerFromBackup(
    newServerData?.datacenter_location_name || "",
    newServerData?.image_id || 0,
    newServerName,
    newServerData?.server_type_name || "",
    newServerData?.type || "",
    newServerData?.provider || "",
    newServerData?.amount || 0,
    newServerData?.iso_name || "",
    newServerData?.image_os_flavor || "",
    false
   )
  );
  setLoading(false);
  handleClose();
  handleReset();
  navigate("/servers");
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return <></>;
   case 1:
    return (
     <TextField
      value={newServerName}
      onChange={(event) => setNewServerName(event.target.value)}
      label={intl.formatMessage({ id: "server.add.name" })}
     />
    );
  }
 };

 const checkDisableConfirm = () => {
  return (
   newServerName.length === 0 ||
   !fqdnRegex.test(newServerName) ||
   nameChecked === "name not available"
  );
 };

 const renderServerChecked = () => {
  switch (nameChecked) {
   case "name available":
    return (
     <Alert severity="success">
      <FormattedMessage id="server.add.nameAvailable" />
     </Alert>
    );
   default:
    return (
     <Alert severity="error">
      <FormattedMessage id="server.add.nameNotAvailable" />
     </Alert>
    );
  }
 };

 return !isLoading ? (
  <Stack spacing={2}>
   <Stack alignItems="center" mt={5} p={3}>
    <DvrIcon fontSize="large" />
    <Typography>
     <FormattedMessage id={"server.newServerFromBackup"} />
    </Typography>
   </Stack>
   <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
    <Box
     sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
     m={10}>
     <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
       <Step key={step.label}>
        <StepLabel>
         <FormattedMessage id={step.label} />
        </StepLabel>
        {!stepLoading ? (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           {index === 1 && nameChecked !== "" && renderServerChecked()}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== steps.length - 1 ? (
             <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="server.add.continue" />
             </Button>
            ) : (
             <></>
            )}
            {index === 1 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={() => (nameChecked === "" ? checkServerName() : handleOpen())}>
               {nameChecked === "" ? (
                <FormattedMessage id="server.add.serverCheck" />
               ) : (
                <FormattedMessage id="server.add.confirm" />
               )}
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="server.add.reset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="server.add.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        ) : (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id="server.checkExecution" />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           <CircularProgress />
          </Stack>
         </StepContent>
        )}
       </Step>
      ))}
     </Stepper>
    </Box>
    <Paper
     elevation={0}
     sx={{
      m: 5,
      p: 2,
      maxHeight: 300,
      position: "sticky",
      top: 30,
      minWidth: 400,
      borderRadius: "10px",
      boxShadow: 0
     }}>
     <Stack mb={2}>
      <Typography variant="h5" fontWeight="bold">
       <FormattedMessage id="server.add.recap" />
      </Typography>
     </Stack>
     <Divider textAlign="left">
      <Typography variant="overline">
       <FormattedMessage id="server.serverData" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.name" />:
      </Typography>
      <Typography>{newServerName}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.type" />:
      </Typography>
      <Typography>{newServerData?.server_type_name}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.cores" />:
      </Typography>
      <Typography>{newServerData?.server_type_cores}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.ram" />:
      </Typography>
      <Typography>{newServerData?.server_type_memory} GB</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.disk" />:
      </Typography>
      <Typography>{newServerData?.server_type_disk} GB</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.provider" />:
      </Typography>
      <Typography>{newServerData?.provider}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.os" />:
      </Typography>
      <Typography>{newServerData?.image_os_flavor}</Typography>
     </Stack>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.location" />:
      </Typography>
      <Typography>
       {locationList.find(
        (element) => element.country === newServerData?.datacenter_location_country
       )?.city || ""}
      </Typography>
      <Stack
       component="span"
       className={`fi fi-${
        locationList
         .find((element) => element.country === newServerData?.datacenter_location_country)
         ?.country.toLocaleLowerCase() || ""
       }`}
      />
     </Stack>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.totalPrice" />
      </Typography>
      <Typography fontSize={"x-large"} fontStyle="italic">
       <FormattedNumber value={newServerData?.amount || 0} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
    </Paper>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.newServer" })}
    handleClose={handleClose}
    disabled={loading || !newServerData?.amount || userBalance < newServerData?.amount}
    handleConfirm={handleConfirm}>
    <Stack spacing={2}>
     <Typography>
      <FormattedMessage id="server.add.confirmNewServer" />
     </Typography>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.balance" />
      </Typography>
      <Typography>
       <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.totalPrice" />
      </Typography>
      <Typography>
       <FormattedNumber value={newServerData?.amount || 0} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
    </Stack>
   </AppModal>
  </Stack>
 ) : (
  <></>
 );
};

export default ServerFromBackupWizard;
