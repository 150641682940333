import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { getServerNameFromId } from "helpers/addons";
import { formatDateColor } from "helpers/app";

import DvrIcon from "@mui/icons-material/Dvr";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { locationList } from "constants/locations";

import ChangeProtection from "components/addons/actions/ChangeProtection";
import VolumesActions from "components/addons/volumes/VolumesActions";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import IconSelector from "components/shared/images/IconSelector";

import { IAllServers } from "types/api/serversApiInterface";
import { volumeTableData } from "types/global/table";

const VolumesTable = ({
 rowData,
 index,
 servers
}: {
 rowData: volumeTableData;
 index: number;
 servers: Array<IAllServers>;
}): ReactElement => {
 const intl = useIntl();

 const RenderLocation = (location: string) => {
  const filteredLocation = locationList.filter((data) => location === data.tag);

  return filteredLocation.map((element, index) => (
   <Stack key={`location-item-${index}`} direction="row" spacing={1}>
    <Stack component="span" className={`fi fi-${element.flag}`} />
    <Typography variant="inherit">
     <FormattedMessage id={element.location} />
    </Typography>
   </Stack>
  ));
 };

 const RenderServiceStatus = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
   case "expiring":
    return (
     <IconSelector icon="ReportIcon" props={{ style: { color: "yellow" }, fontSize: "small" }} />
    );
   case "expired":
    return (
     <IconSelector
      icon="ReportProblemIcon"
      props={{ style: { color: "red" }, fontSize: "small" }}
     />
    );
   default:
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
  }
 };

 const RenderStatus = ({ status }: { status: string }) => {
  switch (status) {
   case "available":
    return <Chip color={"success"} size="small" label={status} />;
   default:
    return <Chip color={"error"} size="small" label={status} />;
  }
 };

 return (
  <TableRow key={`volumes-list-${index}`}>
   <TableCell component="th" scope="row">
    <Stack direction="column">
     <Stack direction="row" alignItems="center" spacing={1}>
      <RenderServiceStatus status={rowData.service_status} />
      <Typography variant="inherit" fontWeight="bold">
       {rowData.name}
      </Typography>
     </Stack>
     {RenderLocation(rowData.datacenter)}
     <Stack direction="row" alignItems="center" spacing={1}>
      <DvrIcon fontSize="small" />
      <Typography variant="inherit">
       {rowData.server ? (
        getServerNameFromId(rowData.server, servers)
       ) : (
        <FormattedMessage id="addons.volumes.notAssigned" />
       )}
      </Typography>
     </Stack>
     <Stack
      onClick={(event) => event.stopPropagation()}
      sx={{ cursor: "pointer" }}
      maxWidth="fit-content">
      <AdminLogAsUser userid={rowData.userid || ""} owner={rowData.personal_name || ""} />
     </Stack>
    </Stack>
   </TableCell>
   <TableCell>
    <RenderStatus status={rowData.status} />
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="inherit">{`${rowData.size} ${intl.formatMessage({
     id: "server.manage.gb"
    })}`}</Typography>
   </TableCell>
   <TableCell>
    <ChangeProtection id={rowData.id} protection={rowData.protection} type="volumes" />
   </TableCell>
   <TableCell>
    <Stack>
     <Typography variant="caption" color={formatDateColor(rowData.service_status)}>
      <FormattedDate value={rowData.expire} />
     </Typography>
     {rowData.service_status === "expired" && (
      <Typography variant="caption" color="error">
       {rowData.service_status}
      </Typography>
     )}
    </Stack>
   </TableCell>
   <TableCell>
    {rowData.service_status !== "expired" ? (
     <VolumesActions rowData={rowData} />
    ) : (
     <Typography variant="caption" fontStyle="italic">
      No action available
     </Typography>
    )}
   </TableCell>
  </TableRow>
 );
};

export default VolumesTable;
