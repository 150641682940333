import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postCreateNewBackup } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CreateBackup = ({ id, hasDb }: { id: number; hasDb: boolean }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [selectedType, setSelectedType] = useState<string>("");
 const [directory, setDirectory] = useState<string>("");

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  if (directory !== "") await dispatch(postCreateNewBackup(id, selectedType, directory));
  else await dispatch(postCreateNewBackup(id, selectedType));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="docker.website.createBackup" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.createBackup" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || selectedType === ""}>
    <Stack spacing={2}>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="docker.website.createBackupMessage" />
      </Typography>
     </Alert>
     <FormControl>
      <InputLabel>
       <FormattedMessage id="docker.website.containerType" />
      </InputLabel>
      <Select
       value={selectedType}
       label={<FormattedMessage id="docker.website.containerType" />}
       onChange={(e) => setSelectedType(e.target.value)}>
       <MenuItem value="site">
        <Typography>
         <FormattedMessage id="docker.website.containerSite" />
        </Typography>
       </MenuItem>
       <MenuItem value="db" disabled={!hasDb}>
        <Typography>
         <FormattedMessage id="docker.website.containerDatabase" />
        </Typography>
       </MenuItem>
      </Select>
     </FormControl>
     <TextField
      autoComplete="off"
      value={directory}
      label={intl.formatMessage({ id: "docker.website.backupDirectory" })}
      InputLabelProps={{ shrink: true }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => setDirectory(currentTarget.value)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default CreateBackup;
