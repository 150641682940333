import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getAddonsPermissions, getServerNameFromId } from "helpers/addons";
import { formatExpireColor } from "helpers/numberFormatting";

import DvrIcon from "@mui/icons-material/Dvr";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ChangeProtection from "components/addons/actions/ChangeProtection";
import SnapshotsActions from "components/addons/snapshots/SnapshotsActions";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import ProviderImage from "components/shared/images/ProviderImage";

import { getSnapshotPermissions } from "redux/selectors/permissionSelector";

import { IAllServers } from "types/api/serversApiInterface";
import { ISnapshotPermissionApi } from "types/api/userApiInterface";
import { snapshotTableData } from "types/global/table";

const SnapshotsTable = ({
 rowData,
 index,
 servers
}: {
 rowData: snapshotTableData;
 index: number;
 servers: Array<IAllServers>;
}): ReactElement => {
 const intl = useIntl();

 const permissions = useSelector(getSnapshotPermissions);

 const findPermission = (): ISnapshotPermissionApi | null => {
  return getAddonsPermissions(rowData.provider, permissions || []);
 };

 return (
  <TableRow key={`snapshot-list-${index}`}>
   <TableCell component="th" scope="row">
    <Stack direction="column">
     <Stack direction="row" spacing={1}>
      <ProviderImage provider={rowData.provider} width={20} />
      <Typography variant="inherit" fontWeight="bold">
       {rowData.description}
      </Typography>
     </Stack>
     <Stack direction="row" alignItems="center">
      <DvrIcon fontSize="small" sx={{ marginRight: 1 }} />
      <Typography variant="inherit">
       {rowData.server_id ? (
        getServerNameFromId(rowData.server_id, servers)
       ) : (
        <FormattedMessage id="server.snapshot.snapshotNotAvailable" />
       )}
      </Typography>
     </Stack>
     <Stack
      onClick={(event) => event.stopPropagation()}
      sx={{ cursor: "pointer" }}
      maxWidth="fit-content">
      <AdminLogAsUser userid={rowData.userid || ""} owner={rowData.personal_name || ""} />
     </Stack>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.image_size ? (
     <Typography variant="inherit">{`${Number(rowData.image_size).toFixed(2)} ${intl.formatMessage({
      id: "server.manage.gb"
     })}`}</Typography>
    ) : (
     <Typography fontStyle="italic" variant="caption">
      Not available
     </Typography>
    )}
   </TableCell>
   <TableCell>
    <Stack>
     <Typography
      fontSize={"small"}
      variant="caption"
      color={formatExpireColor(rowData.expire) ? "#000" : "#f44336"}>
      {`${intl.formatMessage({
       id: "server.snapshot.snapshotExpiring"
      })}: `}
      <FormattedDate value={rowData.expire} />
     </Typography>
    </Stack>
   </TableCell>
   <TableCell>
    {findPermission()?.enable_protection ? (
     <ChangeProtection id={rowData.id} protection={rowData.protection} type="snapshot" />
    ) : (
     <Chip
      icon={rowData.protection ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
      color={rowData.protection ? "success" : "error"}
      size="small"
      label={
       rowData.protection
        ? intl.formatMessage({ id: "addons.enabled" })
        : intl.formatMessage({ id: "addons.disabled" })
      }
     />
    )}
   </TableCell>
   <TableCell>
    <SnapshotsActions
     rowData={rowData}
     server={rowData.server_id ? getServerNameFromId(rowData.server_id, servers) : ""}
     findedPermission={findPermission() || null}
    />
   </TableCell>
  </TableRow>
 );
};

export default SnapshotsTable;
