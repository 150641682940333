export const getErrorMessage = (error: string): string => {
 return errorData.find((element) => element.errorN === error)?.message || "error.noError";
};

const errorData = [
 {
  errorN: "e00",
  message: "error.noError"
 },
 {
  errorN: "e01",
  message: "error.noServerAvailable"
 },
 {
  errorN: "e02",
  message: "error.nameAlreadyTaken"
 },
 {
  errorN: "e03",
  message: "error.networkIpRangeOverlaps"
 },
 {
  errorN: "e04",
  message: "error.serverAlreadyAttached"
 },
 {
  errorN: "e05",
  message: "error.cannotResetPasswordCauseAgent"
 },
 {
  errorN: "e06",
  message: "error.serverRebuildIsProtected"
 },
 {
  errorN: "e07",
  message: "error.connectionTimedOut"
 },
 {
  errorN: "e08",
  message: "error.connectionToAgentFailed"
 },
 {
  errorN: "e09",
  message: "error.resourceNotAvailable"
 },
 {
  errorN: "e10",
  message: "error.serverTypeNotFound"
 }
];
