import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import CancelIcon from "@mui/icons-material/Cancel";

import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { deleteRecord } from "redux/handlers/zoneHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { Record } from "types/api/appsApiInterface";

import AddRecord from "../recordsActions/AddRecord";
import EditRecord from "../recordsActions/EditRecord";

const RecordTableEntry = ({
 rowData,
 ispId,
 zoneId,
 index,
 domain,
 resetIndex,
 resetList,
 updateList
}: {
 rowData: Record;
 ispId: number;
 zoneId: number;
 index: number;
 domain: string;
 resetIndex: boolean;
 resetList: () => void;
 updateList: () => void;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const [editIndex, setEditIndex] = useState<number>(-1);
 const [deleteIndex, setDeleteIndex] = useState<number>(-1);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  if (resetIndex) {
   setDeleteIndex(-1);
   setEditIndex(-1);
  }
 }, [resetIndex]);

 const RenderRow = ({ row }: { row: Record }): ReactElement => {
  return (
   <>
    <TableCell component="th" scope="row" align="left">
     {row.type}
    </TableCell>
    <TableCell component="th" scope="row" align="center">
     {row.name}
    </TableCell>
    <TableCell component="th" scope="row" align="center">
     <Typography variant="subtitle2" sx={{ "overflow-wrap": "break-word", width: 300 }}>
      {row.data}
     </Typography>
    </TableCell>
    <TableCell component="th" scope="row" align="center">
     {row.aux}
    </TableCell>
    <TableCell component="th" scope="row" align="center">
     {row.ttl}
    </TableCell>
   </>
  );
 };

 const RenderConfirmDelete = ({ index }: { index: number }): ReactElement => {
  return !loading ? (
   <Stack justifyContent="center">
    <Typography variant="subtitle2" color="error">
     <FormattedMessage id="domains.record.delete" />
    </Typography>
    <Stack direction="row" justifyContent="center">
     <IconButton
      onClick={() => {
       handleDeleteRecord(index);
      }}>
      <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
     </IconButton>
     <IconButton
      onClick={() => {
       setDeleteIndex(-1);
      }}>
      <CancelIcon color="action" />
     </IconButton>
    </Stack>
   </Stack>
  ) : (
   <CircularProgress />
  );
 };

 const handleDeleteRecord = async (index: number) => {
  setLoading(true);
  await dispatch(deleteRecord(rowData.id, rowData.isp_id, rowData.type));
  setEditIndex(-1);
  setDeleteIndex(-1);
  setLoading(false);
 };

 const handleReset = () => {
  setEditIndex(-1);
  resetList();
 };

 const handleUpdate = () => {
  updateList();
 };

 return rowData.id === -1 ? (
  <AddRecord
   key={`add-index-${index}`}
   zoneId={zoneId}
   ispId={ispId}
   domain={domain}
   resetIndex={handleReset}
   updateData={handleUpdate}
  />
 ) : editIndex === index ? (
  <EditRecord
   key={`add-index-${index}`}
   zoneId={zoneId}
   ispId={ispId}
   domain={domain}
   rowData={rowData}
   resetIndex={handleReset}
   updateData={handleUpdate}
  />
 ) : (
  <TableRow key={`record-list-${index}`} id={`record-element-${index}`}>
   <RenderRow row={rowData} />
   <TableCell component="th" scope="row" align="center" width="10%">
    {deleteIndex === index ? (
     <RenderConfirmDelete index={index} />
    ) : rowData.status === "active" ? (
     <Stack direction="row" justifyContent="center">
      {rowData.type !== "NS" && (
       <IconButton
        onClick={() => {
         setEditIndex(index);
         setDeleteIndex(-1);
        }}>
        <Tooltip title="Edit" placement="top">
         <IconSelector icon="EditIcon" props={{ color: "action" }} />
        </Tooltip>
       </IconButton>
      )}
      <IconButton
       onClick={() => {
        setEditIndex(-1);
        setDeleteIndex(index);
       }}>
       <Tooltip title={`Delete Record ${rowData.name}`} placement="top">
        <IconSelector icon="DeleteIcon" props={{ color: "error" }} />
       </Tooltip>
      </IconButton>
     </Stack>
    ) : (
     <CircularProgress />
    )}
   </TableCell>
  </TableRow>
 );
};

export default RecordTableEntry;
