import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import RegistarImage from "components/shared/images/RegistarImage";

import { getDomainsList } from "redux/selectors/domainsSelector";

import SendVerificationInList from "../actions/SendVerificationInList";

const DomainsDetails = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const domainData = useSelector(getDomainsList)[0];

 const emailVerificationMessage = (emailVerified: number): ReactElement => {
  switch (emailVerified) {
   case 0:
    return (
     <Typography variant="caption" color="error">
      <FormattedMessage id="domain.emailVerificationFailed" />
     </Typography>
    );
   case 1:
    return (
     <Typography variant="caption">
      <FormattedMessage id="domain.emailVerificationNeeded" />
     </Typography>
    );
   case 2:
    return (
     <Typography variant="caption">
      <FormattedMessage id="domain.emailVerificationPending" />
     </Typography>
    );
   default:
    return <></>;
  }
 };

 return (
  <Stack
   mt={desktopViewPort ? 5 : 1}
   justifyContent="space-between"
   direction={desktopViewPort ? "row" : "column"}
   spacing={2}
   pb={2}>
   <Stack>
    <Stack direction="row" alignItems="center" spacing={2}>
     <RegistarImage provider={domainData.provider} />
     <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
      {domainData.name}
     </Typography>
    </Stack>
   </Stack>
   <Stack>
    <Stack>
     <Stack direction="row" justifyContent="flex-end">
      {!isNil(domainData.emailVerified) && domainData.emailVerified !== 3 && (
       <Typography variant="caption">{domainData.email}</Typography>
      )}
     </Stack>
     <Stack direction="row" alignItems="center">
      {emailVerificationMessage(domainData.emailVerified)}
      {!isNil(domainData.emailVerified) && domainData.emailVerified !== 3 && (
       <SendVerificationInList id={domainData.id} />
      )}
     </Stack>
    </Stack>
   </Stack>
  </Stack>
 );
};

export default DomainsDetails;
