import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import useDebounce from "react-use/lib/useDebounce";
import { AppContext } from "AppContext";
import { getJobInProgress } from "helpers/server";

import { isNil } from "ramda";

import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import popoverClasses from "@mui/material/Popover/popoverClasses";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { getDockerOrders } from "redux/handlers/dockerHandler";
import { getGatewayOrders } from "redux/handlers/gatewayHandler";
import { getServerOrders } from "redux/handlers/hetznerOrders";
import { getAllAppJobqueue } from "redux/handlers/jobqueueHandler";

import { getDockerOrderList } from "redux/selectors/dockerOrdersSelector";
import { getGatewayOrderList } from "redux/selectors/gatewayOrdersSelector";
import { getJobqueueList } from "redux/selectors/jobqueueSelector";
import { getServerJobsList } from "redux/selectors/serverJobQueueSelector";

import { useAppDispatch } from "hooks/reduxHook";

const HeaderJobQueue = (): ReactElement => {
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const buttonRef = useRef(null);

 const jobList = useSelector(getJobqueueList);
 const serverJobList = useSelector(getServerJobsList);
 const gatewayOrderList = useSelector(getGatewayOrderList);
 const dockerOrderList = useSelector(getDockerOrderList);

 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const [reloadData, setReloadData] = useState<number>(0);
 const open = Boolean(anchorEl);

 const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("server_jobs", async (value): Promise<void> => {
    if (value?.completed) {
     setReloadData(1);
    }
   });
   socket.on("app_orders", async (value): Promise<void> => {
    if (value?.completed) {
     setReloadData(2);
    }
   });
   socket.on("gateway_orders", async (value): Promise<void> => {
    if (value?.completed) {
     setReloadData(3);
    }
   });
   socket.on("docker_order", async (value): Promise<void> => {
    if (value?.completed) {
     setReloadData(4);
    }
   });
  }
  return () => {
   if (!isNil(socket)) {
    socket.off("server_jobs");
    socket.off("app_orders");
    socket.off("gateway_orders");
    socket.off("docker_order");
   }
  };
 }, [socket]);

 useDebounce(
  async () => {
   if (reloadData === 1) {
    await dispatch(getServerOrders(0, 10));
    setReloadData(0);
   } else if (reloadData === 2) {
    await dispatch(getAllAppJobqueue(0, 10));
    setReloadData(0);
   } else if (reloadData === 3) {
    await dispatch(getGatewayOrders(0, 10));
    setReloadData(0);
   } else if (reloadData === 4) {
    await dispatch(getDockerOrders(0, 10));
    setReloadData(0);
   }
  },
  1000,
  [reloadData]
 );

 useEffect(() => {
  if (
   getJobInProgress(jobList || []) === 0 &&
   getJobInProgress(serverJobList || []) === 0 &&
   getJobInProgress(gatewayOrderList || []) === 0 &&
   getJobInProgress(dockerOrderList || []) === 0
  ) {
   handleClose();
  }
 }, [jobList, serverJobList, gatewayOrderList]);

 const renderChip = (data: string) => {
  switch (data) {
   case "success":
    return <Chip size="small" label={data} color="success" />;
   case "failed":
    return <Chip size="small" label={data} color="error" />;
   default:
    return <Chip size="small" label={data} color="primary" />;
  }
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="app.jobOngoing" />}>
    <IconButton
     size="large"
     aria-label="show 17 new notifications"
     color="inherit"
     onClick={handleClick}
     ref={buttonRef}>
     <Badge
      badgeContent={
       getJobInProgress(serverJobList || []) +
       getJobInProgress(jobList || []) +
       getJobInProgress(gatewayOrderList || []) +
       getJobInProgress(dockerOrderList || [])
      }
      color="error">
      <WorkHistoryIcon />
     </Badge>
    </IconButton>
   </Tooltip>
   <Menu
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    sx={{
     [`& .${popoverClasses.paper}`]: {
      borderRadius: 1
     }
    }}
    anchorOrigin={{
     vertical: "bottom",
     horizontal: "left"
    }}
    transformOrigin={{
     vertical: "top",
     horizontal: "left"
    }}>
    <Stack alignItems="center" px={2}>
     <Stack direction="row" width="100%" py={1}>
      <Typography variant="h5">
       <FormattedMessage id="app.jobOngoing" />
      </Typography>
     </Stack>
     <Divider flexItem />
    </Stack>
    <MenuList dense>
     {getJobInProgress(jobList || []) === 0 &&
      getJobInProgress(serverJobList || []) === 0 &&
      getJobInProgress(gatewayOrderList || []) === 0 &&
      getJobInProgress(dockerOrderList || []) === 0 && (
       <Stack px={2}>
        <Typography variant="caption" fontStyle="italic" sx={{ opacity: "0.5" }}>
         <FormattedMessage id="app.noJobsFound" />
        </Typography>
       </Stack>
      )}
     {jobList
      ?.filter((command) => command.status !== "success" && command.status !== "failed")
      .map((command, index) => {
       return (
        <Stack key={`notification-dropdown-${index}`} px={2}>
         <MenuItem>
          <Stack spacing={2} direction="row" width="stretch" justifyContent="space-between">
           {renderChip(command.status)}
           <Typography>{command.type}</Typography>
           <Typography>{command.action}</Typography>
           <Typography>
            <FormattedDate value={command.createdAt} />
           </Typography>
          </Stack>
         </MenuItem>
         <Divider flexItem />
        </Stack>
       );
      })}
     {serverJobList
      ?.filter((command) => command.status !== "success" && command.status !== "failed")
      .map((command, index) => {
       return (
        <Stack key={`notification-dropdown-${index}`} px={2}>
         <MenuItem>
          <Stack spacing={2} direction="row" width="stretch" justifyContent="space-between">
           {renderChip(command.status)}
           <Typography>{command.action}</Typography>
           <Typography>{command.response}</Typography>
          </Stack>
         </MenuItem>
         <Divider flexItem />
        </Stack>
       );
      })}
     {gatewayOrderList
      ?.filter((command) => command.status !== "success" && command.status !== "failed")
      .map((command, index) => {
       return (
        <Stack key={`notification-dropdown-${index}`} px={2}>
         <MenuItem>
          <Stack spacing={2} direction="row" width="stretch" justifyContent="space-between">
           {renderChip(command.status)}
           <Typography>{command.action}</Typography>
           <Typography>{command.response}</Typography>
          </Stack>
         </MenuItem>
         <Divider flexItem />
        </Stack>
       );
      })}
     {dockerOrderList
      ?.filter((command) => command.status !== "success" && command.status !== "failed")
      .map((command, index) => {
       return (
        <Stack key={`notification-dropdown-${index}`} px={2}>
         <MenuItem>
          <Stack spacing={2} direction="row" width="stretch" justifyContent="space-between">
           {renderChip(command.status)}
           <Typography>{command.action}</Typography>
           <Typography>{command.response}</Typography>
          </Stack>
         </MenuItem>
         <Divider flexItem />
        </Stack>
       );
      })}
    </MenuList>
   </Menu>
  </>
 );
};

export default HeaderJobQueue;
