import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import {
 getNotifications,
 postDeleteAllNotific,
 postDeleteNotification,
 postMarkAllAsRead,
 postMarkAsRead
} from "redux/handlers/notificationHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { INotification } from "types/api/notificationApiInterface";

const NotificationBox = (): ReactElement => {
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const [notificationList, setNotificationList] = useState<Array<INotification>>([]);
 const [notificationTotalList, setNotificationTotalList] = useState<Array<INotification>>([]);
 const [notificationNumber, setNotificationNumber] = useState<number>(0);
 const [reloadData, setReloadData] = useState<boolean>(true);
 const [startingPoint, setStartingPoint] = useState<number>(0);
 const [lastPoint, setLastPoint] = useState<number>(3);
 const [loading, setLoading] = useState<boolean>(false);
 const [firstLoad, setFirstLoad] = useState<boolean>(true);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("app_notify", async (value): Promise<void> => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  }
  return () => {
   if (!isNil(socket)) socket.off("app_notify");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    let notList = await getNotifications();
    setNotificationTotalList(notList);
    setNotificationList(
     notList.filter((element, index) => index > startingPoint && index <= lastPoint)
    );
    setNotificationNumber(notList.filter((element) => !element.isSeen).length);
    setReloadData(false);
    setFirstLoad(false);
   }
  })();
 }, [reloadData]);

 useEffect(() => {
  if (!firstLoad) {
   setLoading(true);
   setNotificationList(
    notificationTotalList.filter((element, index) => index > startingPoint && index <= lastPoint)
   );
   setLoading(false);
  }
 }, [startingPoint]);

 const handleReadNotification = async (id: number) => {
  await dispatch(postMarkAsRead(id));
  setReloadData(true);
 };

 const handleDeleteNotification = async (id: number) => {
  await dispatch(postDeleteNotification(id));
  setReloadData(true);
 };

 const handleMarkAllAsRead = async () => {
  await dispatch(postMarkAllAsRead());
  setReloadData(true);
 };

 const handleDeleteAll = async () => {
  await dispatch(postDeleteAllNotific());
  setReloadData(true);
 };

 const augmentPage = () => {
  setStartingPoint((last) => last + 3);
  setLastPoint((last) => last + 3);
 };

 const reducePage = () => {
  setStartingPoint((last) => last - 3);
  setLastPoint((last) => last - 3);
 };

 const RenderNotifications = ({ notification }: { notification: INotification }): ReactElement => {
  if (notification.isSeen) {
   return (
    <Stack sx={{ width: "100%" }}>
     <Stack direction="row" spacing={2} justifyContent="space-between">
      <Stack alignItems="flex-start">
       <Typography textAlign="start">{notification.action}</Typography>
       <Typography textAlign="start" variant="subtitle2">
        {notification.text}
       </Typography>
      </Stack>
      <Stack>
       <IconButton
        onClick={(e) => {
         e.stopPropagation();
         handleDeleteNotification(notification.id);
        }}>
        <IconSelector icon="DeleteIcon" props={{ color: "error", sx: { fontSize: 15 } }} />
       </IconButton>
      </Stack>
     </Stack>
     <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="center">
       <NotificationsIcon color="disabled" sx={{ fontSize: 10 }} />
       <Typography variant="caption">Già letta</Typography>
      </Stack>
      <Typography variant="caption">
       <FormattedDate value={notification.createdAt} />
      </Typography>
     </Stack>
    </Stack>
   );
  } else {
   return (
    <Tooltip title="Segna come già letta">
     <Stack
      sx={{ width: "100%", cursor: "pointer" }}
      onClick={() => handleReadNotification(notification.id)}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
       <Stack alignItems="left">
        <Typography textAlign="start">{notification.action}</Typography>
        <Typography textAlign="start" variant="subtitle2">
         {notification.text}
        </Typography>
       </Stack>
       <Stack>
        <IconButton
         onClick={(e) => {
          e.stopPropagation();
          handleDeleteNotification(notification.id);
         }}>
         <IconSelector icon="DeleteIcon" props={{ color: "error", sx: { fontSize: 15 } }} />
        </IconButton>
       </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
       <Stack direction="row" spacing={1} alignItems="center">
        <NotificationsActiveIcon color="success" sx={{ fontSize: 10 }} />
        <Typography variant="caption">Non letta</Typography>
       </Stack>
       <Typography variant="caption">
        <FormattedDate value={notification.createdAt} />
       </Typography>
      </Stack>
     </Stack>
    </Tooltip>
   );
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, p: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" pb={1} justifyContent="space-between">
    <Stack direction="row">
     <Typography fontWeight="bold" variant="h5" textTransform="uppercase">
      <FormattedMessage id="app.notification" />
     </Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     {notificationNumber !== 0 && (
      <Tooltip title="Segna tutte come lette">
       <IconButton onClick={handleMarkAllAsRead}>
        <MarkChatReadIcon sx={{ fontSize: 15 }} />
       </IconButton>
      </Tooltip>
     )}
     {notificationNumber !== 0 && (
      <Tooltip title="Elimina tutte">
       <IconButton onClick={handleDeleteAll}>
        <DeleteSweepIcon sx={{ fontSize: 15 }} />
       </IconButton>
      </Tooltip>
     )}
    </Stack>
   </Stack>
   <Stack>
    {notificationTotalList.length > 0 ? (
     notificationList.map((notification, index) => {
      return (
       <Stack
        key={`notification-dropdown-${index}`}
        sx={{ backgroundColor: notification.isSeen ? "#F0F0F0" : "white" }}>
        <Stack>
         <RenderNotifications notification={notification} />
         <Divider />
        </Stack>
       </Stack>
      );
     })
    ) : (
     <Stack px={2}>
      <Typography variant="caption" fontStyle="italic" sx={{ opacity: "0.5" }}>
       <FormattedMessage id="notifications.noNotificationsFound" />
      </Typography>
     </Stack>
    )}
   </Stack>
   {notificationTotalList.length > 0 &&
    (!loading ? (
     <Stack direction="row">
      <Button onClick={reducePage} disabled={startingPoint === 0}>
       previous
      </Button>
      <Button onClick={augmentPage} disabled={lastPoint + 3 >= notificationTotalList.length}>
       next
      </Button>
     </Stack>
    ) : (
     <CircularProgress />
    ))}
  </Paper>
 );
};

export default NotificationBox;
