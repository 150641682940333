import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postApplySsl, postRevokeSslCertificate } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DockerWebsiteSsl = ({ id, check }: { id: number; check: boolean }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [sslOn, setSslOn] = useState<boolean>(false);
 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRevokeSslCertificate(id));
  setLoading(false);
  handleClose();
  setSslOn(false);
 };

 useEffect(() => {
  setSslOn(check);
 }, [check]);

 const handleChangeSsl = async (newCheck: boolean) => {
  if (newCheck === false) {
   handleOpen();
  } else {
   setSslOn(newCheck);
   await dispatch(postApplySsl(id));
  }
 };

 return (
  <>
   <Switch
    checked={sslOn}
    onChange={(event) => {
     handleChangeSsl(event.target.checked);
    }}
   />
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.deleteSslCertificate" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack spacing={2}>
     <Alert severity="warning">
      <Typography variant="subtitle2">
       <FormattedMessage id="docker.website.deleteSslCertMessage" />
      </Typography>
     </Alert>
     <Typography>
      <FormattedMessage id="docker.website.deleteSslCertMessage2" />
     </Typography>
    </Stack>
   </AppModal>
  </>
 );
};

export default DockerWebsiteSsl;
