import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";

import SendIcon from "@mui/icons-material/Send";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { postSendAdminMessage } from "redux/handlers/chatHandler";

import { useAppDispatch } from "hooks/reduxHook";
const AdminReply = ({
 user_id,
 reloadData
}: {
 user_id: string;
 reloadData: () => void;
}): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();

 const [message, setMessage] = useState<string>("");

 /*
 const handleKeyDown = (event: any) => {
  if (event.key === "Enter") {
   sendMessage(message);
  }
 };
 */

 const sendMessage = async (message: string) => {
  setMessage("");
  await dispatch(postSendAdminMessage(message, user_id));
 };

 return (
  <Stack direction={"row"} px={2} py={0.5} alignItems={"center"}>
   <TextField
    className="message-input"
    value={message}
    multiline
    fullWidth
    size="small"
    onChange={(e) => setMessage(e.target.value)}
    placeholder={intl.formatMessage({ id: "chat.insertMessage" })}
   />
   <IconButton className="send-button" onClick={() => sendMessage(message)}>
    <SendIcon />
   </IconButton>
  </Stack>
 );
};

export default AdminReply;
