import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useDebounce from "react-use/lib/useDebounce";
import { AppContext } from "AppContext";
import { handleChangePageNumber } from "helpers/pagination";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { mailsTable } from "constants/tables";

import NoServerFound from "components/shared/emptyList/NoServerFound";
import SortingTable from "components/shared/tables/SortingTable";

import { getAllMailsDomain, getSpamfilterData } from "redux/handlers/mailsHandler";
import { postSyncronizeSocket } from "redux/handlers/socketHandler";

import { getIspList } from "redux/selectors/ispSelector";
import { getMailNumber, getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ISpamSelector } from "types/api/mailApiInterface";

const MailsList = (): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const location = useLocation();
 const { socket } = useContext(AppContext);

 const mailsList = useSelector(getMailsList);
 const ispList = useSelector(getIspList);
 const totalMails = useSelector(getMailNumber);

 const searchRef = useRef<HTMLInputElement | null>(null);
 const [searchValue, setSearchValue] = useState<string>("");
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [selectedIsp, setSelectedIsp] = useState<number>(0);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [reloadData, setReloadData] = useState<boolean>(false);
 const [spamList, setSpamList] = useState<Array<ISpamSelector>>([]);
 const [mailSizeArr, setMailSizeArr] = useState<Array<{ domain: string; disk: string }>>([]);

 useEffect(() => {
  (async () => {
   if (location.state && location.state.server) {
    setSelectedIsp(ispList.find((element) => element.server_id === location.state.server)?.id || 0);
   }
   setSpamList(await getSpamfilterData());
   await dispatch(postSyncronizeSocket("maildomain"));
   setIsLoading(false);
  })();
 }, []);

 const parseSizing = (value: number, type: string): string => {
  if (type && type.includes("M")) {
   return `${value} MB`;
  } else if (type && type.includes("G")) {
   return `${value} GB`;
  } else {
   return `${value} KB`;
  }
 };

 useEffect(() => {
  !isNil(socket) &&
   socket.on("app_data", (value): void => {
    if (value?.completed && value?.section === "mail") {
     setReloadData(true);
    }
   });
  !isNil(socket) &&
   socket.on("maildomain_logs", (value): void => {
    if (value?.length > 0) {
     let tempArr = [];
     for (let i = 0; i < value.length; i++) {
      tempArr.push({
       domain: value[i].domain,
       disk: parseSizing(parseInt(value[i].mail_size), value[i].mail_size)
      });
     }
     setMailSizeArr(tempArr);
    }
   });
  return () => {
   !isNil(socket) && socket.off("app_data");
   !isNil(socket) && socket.off("maildomain_logs");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getAllMailsDomain(currentPage, rowPerPage, selectedIsp, searchRef?.current?.value));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllMailsDomain(currentPage, rowPerPage, selectedIsp, searchRef?.current?.value));
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const data = handleChangePageNumber(parseInt(event.target.value, 10), currentPage, rowPerPage);
  setRowPerPage(data.newRow);
  setCurrentPage(Number(data.newPage.toFixed(0)));

  dispatch(
   getAllMailsDomain(
    Number(data.newPage.toFixed(0)),
    parseInt(event.target.value, 10),
    selectedIsp,
    searchRef?.current?.value
   )
  );
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
  dispatch(getAllMailsDomain(newPage, rowPerPage, selectedIsp, searchRef?.current?.value));
 };

 const handleIspChange = (id: number) => {
  setSelectedIsp(id);
  dispatch(getAllMailsDomain(currentPage, rowPerPage, id, searchRef?.current?.value));
  setCurrentPage(0);
 };

 const handleRedirectToMailCreateMail = () => {
  navigate("/app/mail/add");
 };

 const handleRedirectToServerCreate = () => {
  navigate("/servers/add");
 };

 return (
  <Stack mt={5}>
   <Stack justifyContent="space-between" alignItems="center" direction="row" pb={1}>
    <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
     <FormattedMessage id="app.mails" />
     {` (${totalMails})`}
    </Typography>
    {ispList.length > 0 ? (
     <Button
      variant="kxActionButton"
      endIcon={<AddIcon />}
      onClick={handleRedirectToMailCreateMail}>
      <FormattedMessage id="mails.add" />
     </Button>
    ) : (
     <Button variant="kxActionButton" endIcon={<AddIcon />} onClick={handleRedirectToServerCreate}>
      <FormattedMessage id="server.action.add" />
     </Button>
    )}
   </Stack>
   {ispList.length > 0 ? (
    <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
     <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row" spacing={2}>
      <TextField
       size="small"
       autoComplete="off"
       inputRef={searchRef}
       label={<FormattedMessage id="search.mails" />}
       InputLabelProps={{ shrink: true }}
       InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
      <Autocomplete
       autoHighlight
       size="small"
       sx={{ mx: 1, width: 240 }}
       onChange={(e, value) => handleIspChange(value && value.id ? value.id : 0)}
       value={
        ispList && selectedIsp !== 0
         ? {
            label: ispList?.find((element) => element.id === selectedIsp)?.hostname,
            id: ispList?.find((element) => element.id === selectedIsp)?.id
           }
         : { label: "", id: 0 }
       }
       options={ispList.map((element) => {
        return {
         label: element.hostname || "",
         id: element.id || 0
        };
       })}
       renderInput={(params) => (
        <TextField {...params} label={"Hosts"} InputLabelProps={{ shrink: true }} />
       )}
      />
     </Stack>
     <Divider sx={{ borderColor: "#f86e06" }} />
     <Stack>
      <SortingTable
       tableData={mailsTable}
       maxWidth={650}
       caller="mails"
       listToShow={mailsList}
       spamfilter={spamList}
       mailPerformance={mailSizeArr}
      />
      <TablePagination
       component="div"
       count={totalMails || 0}
       page={currentPage}
       rowsPerPage={rowPerPage}
       onPageChange={handleOnPageChange}
       onRowsPerPageChange={handleOnRowsPerPageChange}
      />
     </Stack>
    </Paper>
   ) : (
    <NoServerFound type="mailserver" />
   )}
  </Stack>
 );
};

export default MailsList;
