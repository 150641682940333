import React, { ReactElement, useState } from "react";

import { isNil } from "ramda";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { IDockerSiteData } from "types/api/dockerApiInterface";

import BackupSection from "./subsections/BackupSection";
import CronjobSection from "./subsections/CronjobSection";
import DatabaseNotFound from "./subsections/DatabaseNotFound";
import DatabaseSection from "./subsections/DatabaseSection";
import DbNavigatorSection from "./subsections/DbNavigatorSection";
import FilemanagerSection from "./subsections/FilemanagerSection";
import FtpUserSection from "./subsections/FtpuserSection";
import StatisticSection from "./subsections/StatisticSection";

const ContainerWebsiteSpecific = ({ rowData }: { rowData: IDockerSiteData }): ReactElement => {
 const [section, setSection] = useState<number>(0);

 const RenderSection = () => {
  switch (section) {
   case 0:
    return <StatisticSection rowData={rowData} hasDb={!isNil(rowData.container_id_db)} />;
   case 1:
    return rowData.container_id_db ? (
     <DatabaseSection
      id={rowData.id}
      container_id={rowData.container_id_db}
      dbOnline={rowData.db_status || ""}
     />
    ) : (
     <DatabaseNotFound id={rowData.id} />
    );
   case 2:
    return (
     <DbNavigatorSection
      id={rowData.id}
      dbType={rowData.db_type || ""}
      container_id={rowData.container_id_db}
      active={rowData.phpmyadmin_active || rowData.adminer_active}
      adminer={rowData.adminer_active}
     />
    );
   case 3:
    return <CronjobSection id={rowData.id} container_id={rowData.container_id} />;
   case 4:
    return <FtpUserSection id={rowData.id} />;
   case 5:
    return (
     <FilemanagerSection
      id={rowData.id}
      active={rowData.filemanager_active}
      file_password={rowData.filemanager_password}
      file_port={rowData.filemanager_port}
     />
    );
   case 6:
    return (
     <BackupSection
      id={rowData.id}
      hasDb={rowData.container_id_db ? true : false}
      backupData={rowData.backup_data}
     />
    );
   default:
    return <></>;
  }
 };

 return (
  <Stack direction="row" py={2}>
   <Stack key="menu" sx={{ borderRight: "1px solid lightgrey" }}>
    <Stack p={1}>
     <IconButton
      onClick={() => setSection(0)}
      sx={{ backgroundColor: section === 0 ? "#ed6c18" : "transparent" }}>
      {section === 0 ? (
       <IconSelector icon="QueryStatsIcon" props={{ color: "tertiary" }} />
      ) : (
       <IconSelector icon="QueryStatsIcon" />
      )}
     </IconButton>
    </Stack>
    <Divider />
    <Stack p={1}>
     <IconButton
      onClick={() => setSection(1)}
      sx={{ backgroundColor: section === 1 ? "#ed6c18" : "transparent" }}>
      {section === 1 ? (
       <IconSelector icon="DatabaseIcon" props={{ color: "tertiary" }} />
      ) : (
       <IconSelector
        icon="DatabaseIcon"
        props={{ color: rowData.container_id_db ? "success" : "error" }}
       />
      )}
     </IconButton>
    </Stack>
    <Divider />
    <Stack p={1}>
     <IconButton
      disabled={!rowData.container_id_db}
      onClick={() => setSection(2)}
      sx={{ backgroundColor: section === 2 ? "#ed6c18" : "transparent" }}>
      {section === 2 ? (
       <IconSelector icon="DatabaseManagementIcon" props={{ color: "tertiary" }} />
      ) : !rowData.container_id_db ? (
       <IconSelector icon="DatabaseManagementIcon" />
      ) : (
       <IconSelector
        icon="DatabaseManagementIcon"
        props={{ color: rowData.phpmyadmin_active || rowData.adminer_active ? "success" : "error" }}
       />
      )}
     </IconButton>
    </Stack>
    <Divider />
    <Stack p={1}>
     <IconButton
      onClick={() => setSection(3)}
      sx={{ backgroundColor: section === 3 ? "#ed6c18" : "transparent" }}>
      {section === 3 ? (
       <IconSelector icon="CronjobIcon" props={{ color: "tertiary" }} />
      ) : (
       <IconSelector icon="CronjobIcon" />
      )}
     </IconButton>
    </Stack>
    <Divider />
    <Stack p={1}>
     <IconButton
      onClick={() => setSection(4)}
      sx={{ backgroundColor: section === 4 ? "#ed6c18" : "transparent" }}>
      {section === 4 ? (
       <IconSelector icon="FtpIcon" props={{ color: "tertiary" }} />
      ) : (
       <IconSelector icon="FtpIcon" />
      )}
     </IconButton>
    </Stack>
    <Divider />
    <Stack p={1}>
     <IconButton
      onClick={() => setSection(5)}
      sx={{ backgroundColor: section === 5 ? "#ed6c18" : "transparent" }}>
      {section === 5 ? (
       <IconSelector icon="FolderIcon" props={{ color: "tertiary" }} />
      ) : (
       <IconSelector
        icon="FolderIcon"
        props={{ color: rowData.filemanager_active ? "success" : "error" }}
       />
      )}
     </IconButton>
    </Stack>
    <Divider />
    <Stack p={1}>
     <IconButton
      onClick={() => setSection(6)}
      sx={{ backgroundColor: section === 6 ? "#ed6c18" : "transparent" }}>
      {section === 6 ? (
       <IconSelector icon="BackupIcon" props={{ color: "tertiary" }} />
      ) : (
       <IconSelector icon="BackupIcon" />
      )}
     </IconButton>
    </Stack>
    <Divider />
   </Stack>
   <Stack key="content" p={2} width="stretch">
    <RenderSection />
   </Stack>
  </Stack>
 );
};

export default ContainerWebsiteSpecific;
