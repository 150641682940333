import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import CreateBackup from "../../actions/backupActions/CreateBackup";

const BackupNotFound = ({ id, hasDb }: { id: number; hasDb: boolean }): ReactElement => {
 return (
  <Stack spacing={2}>
   <Alert severity="warning">
    <FormattedMessage id="docker.website.noBackupFoundMessage" />
   </Alert>
   <Stack direction="row">
    <CreateBackup id={id} hasDb={hasDb} />
   </Stack>
  </Stack>
 );
};

export default BackupNotFound;
