import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";
import serverDetailsReducer from "redux/reducers/serverDetailsReducer";
import serverServicesReducer from "redux/reducers/serverServicesReducer";
import serversReducer from "redux/reducers/serversReducer";

import { ICloudDirectoryAPI } from "types/api/addonsApiInterface";
import {
 IAllServers,
 ILocations,
 IOsDetails,
 IProviders,
 IServerAddonsApi,
 IServerApi,
 IServerBuckets,
 IServerDetails,
 IServerLocationsAPI,
 IServerService,
 IServerTypes,
 IServerTypesApi,
 OrdersAPI,
 UpgradeTypesAPI
} from "types/api/serversApiInterface";
import { IBackup, IIso, IPhpValues } from "types/redux/serverInterface";

import { ApiService } from "service/ApiService";

export const saveServiceDatas =
 (data: IServerService): AppAction<Promise<void>> =>
 async (dispatch) => {
  dispatch(serverServicesReducer.actions.setServerServices(data));
 };

export const getAllServers =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IServerApi>(
    `/server/getservers?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }`
   );
   dispatch(serversReducer.actions.setServers(data || []));
  } catch (error) {
   console.warn("FAILED TO GET SERVERS DB");
  }
 };

export const getAllServersNotPaged =
 (): AppAction<Promise<Array<IAllServers>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<{
    servers: Array<IAllServers>;
   }>("/server/getserverslist");
   return data.servers;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load backups - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getServerTypeNumber =
 (server: string): AppAction<Promise<{ mail: number; web: number; zones: number }>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{ mail: number; web: number; zones: number }>(
    "/ispconfig/count",
    "POST",
    { server: server }
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get app number - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { mail: 0, web: 0, zones: 0 };
  }
 };

export const postServerDetails =
 (serverId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IServerDetails>(`/server/getserver/${serverId}`);
   dispatch(serverDetailsReducer.actions.setServerDetails(data || {}));
  } catch (error) {
   console.warn("FAILED TO POST SERVER DETAILS");
  }
 };

export const getAllServerOrders =
 (currentIndex: number, sizePerPage: number, q?: string): AppAction<Promise<OrdersAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<OrdersAPI>(
    `/hetznerorder/allserverorders?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get server orders - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getAllServerAddons =
 (id: number): AppAction<Promise<IServerAddonsApi | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IServerAddonsApi>("/server/addons", "POST", { id: id });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get server orders - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const getAllServerCloudbuckets =
 (id: number): AppAction<Promise<Array<IServerBuckets>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IServerBuckets>>("/server/getinstalledbuckets", "POST", {
    id: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get server cloudbuckets - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postFinalizeServer =
 (id: number, type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/hetznerorder/finalizecreation", "POST", {
    id: id,
    type: type
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Finalizing server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to finalize server id: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRetryOrder =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/hetznerorder/retry", "POST", {
    id: id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Restarting the process...",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to restart process server id: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRetryDeprecatedServer =
 (id: number, server_type: string, amount: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/hetznerorder/retry", "POST", {
    id: id,
    server_type,
    amount
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Restarting the process...",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to restart process server id: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRemoveOrder =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/hetznerorder/deleteorder", "POST", {
    id: id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Removing operation...",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to remove operation server id: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResetServer =
 (serverId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/resetserver", "POST", {
    id: serverId
   });
   dispatch(postServerDetails(Number(serverId)));
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Resetting server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset server id: ${serverId} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER RENAME");
  }
 };

export const postPowerOffServer =
 (serverId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/poweroff", "POST", {
    id: serverId
   });
   dispatch(postServerDetails(Number(serverId)));
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Powering off server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to power off server id: ${serverId} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER RENAME");
  }
 };

export const postPowerOnServer =
 (serverId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/poweron", "POST", {
    id: serverId
   });
   dispatch(postServerDetails(Number(serverId)));
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Powering on server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to power on server id: ${serverId} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER RENAME");
  }
 };

export const postResetServerPassword =
 (serverId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/resetrootpassword", "POST", {
    id: serverId
   });
   dispatch(postServerDetails(Number(serverId)));
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Resetting server password",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset password server id: ${serverId} - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER RENAME");
  }
 };

export const postChangeServerLock =
 (serverId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/changelocked", "POST", {
    id: serverId
   });
   dispatch(postServerDetails(Number(serverId)));
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Changing server lock",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change lock server id: ${serverId} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER RENAME");
  }
 };

export const postChangeServerDnsPtr =
 (serverId: number, ip: string, dns_ptr: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/changednsptr", "POST", {
    id: serverId,
    ip: ip,
    dns_ptr: dns_ptr
   });
   dispatch(postServerDetails(Number(serverId)));
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Changing server rDns",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change dns server id: ${serverId} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getRebuildOs =
 (provider_id: number): AppAction<Promise<Array<IOsDetails>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IOsDetails>>("/image/allimages", "POST", {
    provider_id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load backups - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getServersLocations =
 (provider_id: number): AppAction<Promise<Array<ILocations>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<ILocations>>("/location/alllocations", "POST", {
    provider_id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load locations - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getServersSize =
 (location: string, provider_id: number): AppAction<Promise<Array<IServerTypes>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IServerTypes>>("/servertype/allservertypes", "POST", {
    location: location,
    provider_id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load locations - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getServerProvider = (): AppAction<Promise<Array<IProviders>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<IProviders>>("/provider/allproviders");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to load locations - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return [];
 }
};

export const postCheckServerName =
 (name: string): AppAction<Promise<string>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<string>("/server/checkname", "POST", { name: name });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to check server name - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return "";
  }
 };

export const postBuyServer =
 (
  location_id: number,
  image_id: number,
  name: string,
  provider_id: number,
  server_type: string,
  enable_ipv4: boolean,
  enable_ipv6: boolean,
  enable_backup: boolean,
  business_continuity: boolean,
  server_type_finalized: string,
  productId: string | null
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/addserver", "POST", {
    location_id: location_id,
    image_id: image_id,
    name: name,
    provider_id: provider_id,
    server_type: server_type,
    enable_ipv4: enable_ipv4,
    enable_ipv6: enable_ipv6,
    enable_backup: enable_backup,
    business_continuity,
    server_type_finalized: server_type_finalized,
    productId
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Creating server ${name}`,
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create new server ${name} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRebuildServer =
 (osId: number, server_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/rebuildserver", "POST", {
    id: server_id,
    image_id: osId
   });
   dispatch(postServerDetails(Number(server_id)));
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Rebuilding server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to rebuild server id: ${server_id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER REBUILD");
  }
 };

export const postRenameServer =
 (serverId: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/changeservername", "POST", {
    id: serverId,
    server_name: name
   });
   dispatch(postServerDetails(Number(serverId)));
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Renaming server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to rename server id: ${serverId} to ${name} - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER RENAME");
  }
 };

export const postEnableContinuity =
 (id: number, business_continuity: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/services/changebusinesscontinuity", "POST", {
    server_id_db: id,
    business_continuity
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Operation ongoing...",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to enable business continuity - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER RENAME");
  }
 };

export const postManualChangePassword =
 (serverId: number, password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/server/resetpassword", "POST", {
    id: serverId,
    password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing server password",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change password, server id: ${serverId} - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postServiceReboot =
 (token: string, alias: number, parameter: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/agent/createtask", "POST", {
    token: token,
    alias: alias,
    parameter: parameter
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Rebooting server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reboot server ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER DETAILS");
  }
 };

export const postInstallPolicyD = (data: {
 minuteLimit: number;
 dayLimit: number;
 receivingMail: string;
}): void => {
 console.log(data);
};

export const postControlRecord = (record: string): number => {
 console.log(record);
 return 0;
};

export const postRectifyWebmail = (webmail: string): void => {
 console.log(webmail);
};

export const postServerTypes = async (): Promise<IServerTypesApi[]> => {
 try {
  const { data } = await ApiService<IServerTypesApi[]>("/vmservers/servertypes", "GET");
  return data;
 } catch (error) {
  console.warn("FAILED TO POST SERVER TYPES");
  return [];
 }
};

export const postGetBackups = async (server_id: number): Promise<Array<IBackup>> => {
 const { data } = await ApiService("/vmservers/getbackups", "POST", {
  server_id
 });
 return data?.backups[server_id]?.backups;
};

export const postConvertBackup =
 (id: number, server_id: number, server_name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/vmservers/convertbackuptosnapshot", "POST", {
    server_id,
    id,
    server_name
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Backup successfully converted",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to convert backup id: ${server_id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRebuildBackup =
 (id: number, server_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/vmservers/deletebackup", "POST", {
    server_id,
    id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Backup successfully deleted",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete backup id: ${server_id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteBackup =
 (id: number, server_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/vmservers/deletebackup", "POST", {
    server_id,
    id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Backup successfully deleted",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete backup id: ${server_id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEnableBackup =
 (server_id: number, id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/vmservers/enablerecurrentbackup", "POST", {
    server_id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Backup successfully enabled",
     timestamp: Date.now(),
     type: "success"
    })
   );
   dispatch(postServerDetails(id));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to enable backup id: ${server_id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDisableBackup =
 (server_id: number, id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/vmservers/disablerecurrentbackup", "POST", {
    server_id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Backup successfully disabled",
     timestamp: Date.now(),
     type: "success"
    })
   );
   dispatch(postServerDetails(id));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to disable backup id: ${server_id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddBackup =
 (server_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/vmservers/createmanualbackup", "POST", {
    server_id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Backup successfully created",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create backup, server id: ${server_id} - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postSetupAgent =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/installagent", "POST", {
    id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     type: "success",
     message: "Installing agent on the server",
     timestamp: Date.now()
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install agent on server - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   console.warn("FAILED TO POST SERVER DETAILS");
  }
 };

export const postChangeRescale =
 (server_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/changerescale", "POST", {
    id: server_id
   });
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change rescale on server: ${server_id} - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postServerPossibleUpgrades =
 (server_id: number): AppAction<Promise<Array<UpgradeTypesAPI>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<UpgradeTypesAPI>>("/server/serverupgradedata", "POST", {
    id: server_id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get upgrades server: ${server_id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postUpgradeServer =
 (id: number, server_type_id: number, amount: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/upgradeserver", "POST", {
    id: id,
    server_type_id: server_type_id,
    amount: amount
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     type: "success",
     message: "Starting to upgrade server",
     timestamp: Date.now()
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to upgrade server: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpgradeCTBServer =
 (id: number, server_type_id: number, amount: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/contabo/upgraderequest", "POST", {
    id: id,
    server_type_id: server_type_id,
    amount: amount
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     type: "success",
     message: "Starting to upgrade server",
     timestamp: Date.now()
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to upgrade server: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCompleteCTPUpgradeOrder =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/contabo/completeupgrade", "POST", {
    id: id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     type: "success",
     message: "Server upgrade completed",
     timestamp: Date.now()
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to complete order: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRebootAgent =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { status } = await ApiService("/server/restartagent", "POST", {
    id: id
   });
   status === 200 &&
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      type: "success",
      message: "Starting agent reboot",
      timestamp: Date.now()
     })
    );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reboot agent on server: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateAgent =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { status } = await ApiService("/server/upgradeagent", "POST", {
    id: id
   });
   status === 200 &&
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      type: "success",
      message: "Starting agent update",
      timestamp: Date.now()
     })
    );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update agent on server: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRebootService =
 (id: number, service: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/restartservice", "POST", {
    id: id,
    service: service
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Restarting service",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to restart service on server id: ${id} - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postGetServerPhpTypes =
 (server: string): AppAction<Promise<Array<string>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{
    Disabled: Array<string>;
    "Fast-CGI": Array<string>;
    "PHP-FPM": Array<string>;
   }>("/ispconfig/phpversions", "POST", {
    server: server
   });
   let result = [];
   if (data["Fast-CGI"] && data["Fast-CGI"].length > 0) result.push("Fast-CGI");
   if (data["PHP-FPM"] && data["PHP-FPM"].length > 0) result.push("PHP-FPM");
   return result;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get php version on server: ${server} - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postGetServerPhpVersion =
 (server: string): AppAction<Promise<Array<string>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{
    Disabled: Array<string>;
    "Fast-CGI": Array<string>;
    "PHP-FPM": Array<string>;
   }>("/ispconfig/phpversions", "POST", {
    server: server
   });
   if (data["Fast-CGI"] && data["Fast-CGI"].length > 0) return data["Fast-CGI"];
   else if (data["PHP-FPM"] && data["PHP-FPM"].length > 0) return data["PHP-FPM"];
   else return [];
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get php version on server: ${server} - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postGetServerPhpData =
 (version: string, agentoken: string): AppAction<Promise<IPhpValues | null>> =>
 async (dispatch) => {
  const versionFiltered = version.replace("PHP ", "");
  try {
   const { data } = await ApiService<IPhpValues>("/configurephp/getdata", "POST", {
    version: versionFiltered,
    agentoken: agentoken
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get php data on server - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postConfigPhp =
 (
  agentoken: string,
  version: string,
  maxUpload: number,
  memoryLimit: number,
  maxVars: number,
  maxExecution: number,
  phpErrors: boolean,
  timezone: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/configurephp/setdata", "POST", {
    agentoken: agentoken,
    version: version,
    maxUpload: maxUpload,
    memoryLimit: memoryLimit,
    maxVars: maxVars,
    maxExecution: maxExecution,
    phpErrors: phpErrors,
    timezone: timezone
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Configuring PHP",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to save php configuration - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRepairTable =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/repairsqltable", "POST", {
    id: id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Repairing tables",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to repair tables - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeRootLogin =
 (id: number, rootLoginValue: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/rootlogin", "POST", {
    id: id,
    rootLoginValue: rootLoginValue
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Changing permit root login",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change permit root login - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRequestVnc =
 (id: number): AppAction<Promise<{ wss_url: string; password: string }>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{ wss_url: string; password: string }>(
    "/server/requestconsole",
    "POST",
    {
     id: id
    }
   );
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Requesting Console",
     timestamp: Date.now(),
     type: "success"
    })
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get console, server id: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { wss_url: "", password: "" };
  }
 };

export const postFetchIso =
 (id: number): AppAction<Promise<Array<IIso>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IIso>>("/iso/fetchiso", "POST", {
    id: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get console, server id: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postMountIso =
 (id: number, iso_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/iso/attachiso", "POST", {
    id: id,
    iso_id: iso_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mounting ISO in progress",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to mount, server id: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUnmountIso =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/iso/detachiso", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Detaching ISO in progress",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to detach iso, server id: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAgentVersion = (): AppAction<Promise<number>> => async (dispatch) => {
 try {
  const { data } = await ApiService<{ result: number }>("/agent/getAgentVersion");
  return data.result;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to get agent version - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return 0;
 }
};

export const postSetWindowsToken =
 (id: number, token: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/server/settoken", "POST", {
    id: id,
    token: token
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Setting agent token...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to set agent token in server: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeIpv6Status =
 (id: number, enable: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/server/changestatusipv6", "POST", {
    id: id,
    enable: enable
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing IPv6 status...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change IPv6 status on server: ${id} - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getServersLocationNumber =
 (): AppAction<Promise<IServerLocationsAPI | null>> => async (dispatch) => {
  try {
   const { data } = await ApiService<IServerLocationsAPI>("/server/countserversforlocations");
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to obtain server locations number - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const getServersPerformances = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  const { data } = await ApiService("/server/getperformances");
  console.log(data);
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to obtain servers performance - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
 }
};

export const getServerConnection =
 (server_id_db: number): AppAction<Promise<Array<ICloudDirectoryAPI>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<ICloudDirectoryAPI>>(
    "/cloudbox/getdirectories",
    "POST",
    {
     server_id_db
    }
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get metrics - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postServerContaboUpgrades =
 (id: number): AppAction<Promise<Array<UpgradeTypesAPI>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<UpgradeTypesAPI>>(
    "/server/contabo/serverupgradedata",
    "POST",
    {
     id: id
    }
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get upgrades server: ${id} - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };
