import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postDeleteAllDataSite } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DockerDeleteWebsite = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [flagDel, setFlagDel] = useState<boolean>(false);
 const [flagKeep, setFlagKeep] = useState<boolean>(false);
 const [flagCert, setFlagCert] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDeleteAllDataSite(id, flagDel ? flagDel : !flagKeep, flagCert));
  handleClose();
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.deleteWebsite" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="DeleteIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.deleteWebsite" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || (!flagDel && !flagKeep)}>
    <Stack>
     <Typography>
      <FormattedMessage id="docker.website.deleteWebsiteMessage" />
     </Typography>
     <FormControlLabel
      control={
       <Checkbox
        disabled={flagKeep}
        checked={flagDel}
        onChange={(e) => {
         setFlagDel(e.target.checked);
        }}
       />
      }
      label={intl.formatMessage({ id: "docker.website.deleteSiteData" })}
     />
     <FormControlLabel
      control={
       <Checkbox
        disabled={flagDel}
        checked={flagKeep}
        onChange={(e) => {
         setFlagKeep(e.target.checked);
        }}
       />
      }
      label={intl.formatMessage({ id: "docker.website.keepSiteData" })}
     />
     <FormControlLabel
      control={
       <Checkbox
        checked={flagCert}
        onChange={(e) => {
         setFlagCert(e.target.checked);
        }}
       />
      }
      label={intl.formatMessage({ id: "docker.website.deleteSslCertificate" })}
     />
     <Stack spacing={2}>
      {flagDel && (
       <Alert severity="warning">
        <FormattedMessage id="docker.website.deleteSiteDataWarning" />
       </Alert>
      )}
      {flagKeep && (
       <Alert severity="warning">
        <FormattedMessage id="docker.website.keepSiteDataWarning" />
       </Alert>
      )}
      {flagCert && (
       <Alert severity="warning">
        <FormattedMessage id="docker.website.deleteSslCertMessage" />
       </Alert>
      )}
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default DockerDeleteWebsite;
