import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import IconSelector from "components/shared/images/IconSelector";

import { getMailsList } from "redux/selectors/mailsSelector";

import ChangeMailStatus from "./actions/ChangeMailStatus";

const MailMonitoring = (): ReactElement => {
 const { socket } = useContext(AppContext);
 const params = useParams<{ mail_id: string; section: string }>();
 const maildetails = useSelector(getMailsList).find(
  (element) => element.id === Number(params.mail_id)
 );

 const [mailSize, setMailSize] = useState<string>("0 KB");

 const parseSizing = (value: number, type: string): string => {
  if (type && type.includes("M")) {
   return `${value} MB`;
  } else if (type && type.includes("G")) {
   return `${value} GB`;
  } else {
   return `${value} MB`;
  }
 };

 useEffect(() => {
  !isNil(socket) &&
   socket.on("maildomain_logs", async (value): Promise<void> => {
    for (let element of value) {
     if (element.domain === maildetails?.domain) {
      setMailSize(parseSizing(parseInt(element.mail_size), element.mail_size));
     }
    }
   });
  return () => {
   !isNil(socket) && socket.off("maildomain_logs");
  };
 }, [socket]);

 return (
  <Stack>
   <Paper sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Grid container>
     <Grid xs={6} py={1}>
      <Stack mt={2} pl={1}>
       <Typography textAlign="left" fontWeight="bold" textTransform="uppercase" sx={{ pl: 1 }}>
        <FormattedMessage id="app.actions" />
       </Typography>
       <Stack direction="row" justifyContent="flex-start">
        <ChangeMailStatus
         id={maildetails?.id || 0}
         isp_id={maildetails?.isp_id || 0}
         domain={maildetails?.domain || ""}
         status={maildetails?.active || false}
        />
       </Stack>
      </Stack>
     </Grid>
     <Grid xs={6}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" height="100%" pr={2}>
       <Stack justifyContent="center" alignItems="center">
        <IconSelector icon="DiscIcon" />
        <Typography variant="extraSmall" mt={0.5} textTransform="uppercase">
         <FormattedMessage id="server.disk" />
         <FormattedMessage id="server.gigabytes.short" />
        </Typography>
       </Stack>
       <Typography
        sx={{ ml: 2 }}
        variant="extraSmall"
        fontWeight="bold"
        textTransform="uppercase"
        fontSize={13}>
        <FormattedMessage id="sites.ssd" />:{` ${mailSize}`}
       </Typography>
      </Stack>
     </Grid>
    </Grid>
   </Paper>
  </Stack>
 );
};

export default MailMonitoring;
