import React, { ReactElement } from "react";

import IconButton from "@mui/material/IconButton";

import IconSelector from "../images/IconSelector";

const DisabledIconButton = ({ icon }: { icon: string }): ReactElement => {
 return (
  <IconButton disabled>
   <IconSelector icon={icon} />
  </IconButton>
 );
};

export default DisabledIconButton;
