import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { renderOrderPercent } from "helpers/server";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { getErrorMessage } from "constants/errors";

import ConfirmContaboUpgrade from "components/servers/managePages/upgrade/ConfirmContaboUpgrade";
import RemoveOrder from "components/servers/RemoveOrder";
import RetryDeprecatedOrder from "components/servers/RetryDeprecatedOrder";
import RetryOrder from "components/servers/RetryOrder";
import AdminWrapper from "components/shared/admin/AdminWrapper";

import { serverOrdersTableData } from "types/global/table";

const ServerOrdersTable = ({
 rowData,
 index
}: {
 rowData: serverOrdersTableData;
 index: number;
}): ReactElement => {
 const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  return (
   <Box sx={{ position: "relative", display: "inline-flex" }}>
    <CircularProgress variant="determinate" {...props} />
    <Box
     sx={{
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
     }}>
     <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
      props.value
     )}%`}</Typography>
    </Box>
   </Box>
  );
 };

 const renderChip = (data: string) => {
  if (data.toLocaleLowerCase().includes("success")) {
   return <Chip size="small" label={data} color="success" />;
  } else if (data.toLocaleLowerCase().includes("failed")) {
   return <Chip size="small" label={data} color="error" />;
  } else {
   return <Chip size="small" label={data} color="primary" />;
  }
 };

 const renderActions = (data: string, id: number) => {
  if (data.toLocaleLowerCase().includes("update")) {
   return <RemoveOrder id={id} />;
  } else if (data.toLocaleLowerCase().includes("failed")) {
   return (
    <Stack>
     <Typography variant="subtitle2">
      <FormattedMessage id={getErrorMessage(rowData.error)} />
     </Typography>
     {rowData.error === "e10" ? (
      <Stack direction="row">
       <RetryDeprecatedOrder id={id} order={rowData} />
       <RemoveOrder id={id} />
      </Stack>
     ) : (
      <Stack direction="row">
       <RetryOrder id={id} />
       <RemoveOrder id={id} />
      </Stack>
     )}
    </Stack>
   );
  } else if (data.toLocaleLowerCase().includes("ctb upgrade")) {
   return (
    <Stack direction="row">
     <AdminWrapper>
      <ConfirmContaboUpgrade id={id} />
     </AdminWrapper>
    </Stack>
   );
  } else {
   return (
    <Typography variant="subtitle2">
     <FormattedMessage id="app.jobqueue.noActionsAvailable" />
    </Typography>
   );
  }
 };

 return (
  <TableRow key={`job-list-${index}`}>
   <TableCell component="th" scope="row">
    <Stack direction="row" alignItems="center" spacing={2}>
     <CircularProgressWithLabel
      value={renderOrderPercent(rowData.status, rowData.server_type || "")}
     />
     {renderChip(rowData.status)}
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <Typography variant="caption">{rowData.server_name}</Typography>
     {rowData.ip && <Typography variant="caption">{rowData.ip}</Typography>}
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.phase}
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.createdAt ? (
     <FormattedDate value={rowData.createdAt} />
    ) : (
     <FormattedMessage id="app.unavailableData" />
    )}
   </TableCell>
   <TableCell component="th" scope="row">
    {renderActions(rowData.status, rowData.id)}
   </TableCell>
  </TableRow>
 );
};

export default ServerOrdersTable;
