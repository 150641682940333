import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postScheduleBackup } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IBackupSchedule } from "types/api/dockerApiInterface";

const ScheduleBackup = ({
 id,
 hasDb,
 backupData
}: {
 id: number;
 hasDb: boolean;
 backupData: IBackupSchedule;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [selectedType, setSelectedType] = useState<string>("");
 const [selectedFrequency, setSelectedFrequency] = useState<string>("");
 const [retention, setRetention] = useState<number>(1);
 const [scheduleActive, setScheduleActive] = useState<boolean>(false);
 const [directory, setDirectory] = useState<string>("");

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  if (directory !== "")
   await dispatch(
    postScheduleBackup(id, selectedType, scheduleActive, selectedFrequency, retention, directory)
   );
  else
   await dispatch(
    postScheduleBackup(id, selectedType, scheduleActive, selectedFrequency, retention)
   );
  setLoading(false);
  handleClose();
 };

 const checkDisabled = (): boolean => {
  return selectedType === "" || selectedFrequency === "" || retention < 1;
 };

 const checkNumber = (newValue: number) => {
  if (newValue < 1) {
   setRetention(1);
  } else {
   setRetention(newValue);
  }
 };

 useEffect(() => {
  setRetention(backupData.backup_retention || 1);
  setDirectory(backupData.backup_directory || "");
  if (selectedType === "site") {
   setScheduleActive(backupData.site_backup_status || false);
   setSelectedFrequency(backupData.site_backup_frequency || "");
  } else if (selectedType === "db") {
   setScheduleActive(backupData.db_backup_status || false);
   setSelectedFrequency(backupData.db_backup_frequency || "");
  }
 }, [selectedType]);

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="docker.website.scheduleBackup" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.scheduleBackup" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="docker.website.scheduleBackupMessage" />
      </Typography>
     </Alert>
     <FormControl>
      <InputLabel>
       <FormattedMessage id="docker.website.containerType" />
      </InputLabel>
      <Select
       value={selectedType}
       label={<FormattedMessage id="docker.website.containerType" />}
       onChange={(e) => setSelectedType(e.target.value)}>
       <MenuItem value="site">
        <Typography>
         <FormattedMessage id="docker.website.containerSite" />
        </Typography>
       </MenuItem>
       <MenuItem value="db" disabled={!hasDb}>
        <Typography>
         <FormattedMessage id="docker.website.containerDatabase" />
        </Typography>
       </MenuItem>
      </Select>
     </FormControl>
     <FormControlLabel
      control={
       <Switch
        disabled={selectedType === ""}
        checked={scheduleActive}
        onChange={(e) => setScheduleActive(e.target.checked)}
       />
      }
      label={<FormattedMessage id="docker.website.scheduleActive" />}
     />
     <FormControl>
      <InputLabel>
       <FormattedMessage id="docker.website.frequency" />
      </InputLabel>
      <Select
       disabled={selectedType === ""}
       value={selectedFrequency}
       label={<FormattedMessage id="docker.website.frequency" />}
       onChange={(e) => setSelectedFrequency(e.target.value)}>
       <MenuItem value={'"0 0 * * *"'}>
        <Typography>
         <FormattedMessage id="docker.website.frequencyDay" />
        </Typography>
       </MenuItem>
       <MenuItem value={'"0 0 * * 1"'}>
        <Typography>
         <FormattedMessage id="docker.website.frequencyWeek" />
        </Typography>
       </MenuItem>
       <MenuItem value={'"0 0 1 * *"'}>
        <Typography>
         <FormattedMessage id="docker.website.frequencyMonth" />
        </Typography>
       </MenuItem>
      </Select>
     </FormControl>
     <Divider />
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="docker.website.retentionMessage" />
      </Typography>
     </Alert>
     <TextField
      autoComplete="off"
      value={retention}
      disabled={selectedType === ""}
      label={intl.formatMessage({ id: "docker.website.retention" })}
      type="number"
      InputLabelProps={{ shrink: true }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => checkNumber(Number(currentTarget.value))}
     />
     <TextField
      autoComplete="off"
      value={directory}
      disabled={selectedType === ""}
      label={intl.formatMessage({ id: "docker.website.backupDirectory" })}
      InputLabelProps={{ shrink: true }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => setDirectory(currentTarget.value)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default ScheduleBackup;
