import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import "../../../theme/style/terminal.css";

import IconSelector from "../images/IconSelector";

const VncCommandProgress = (): ReactElement => {
 const { socket } = useContext(AppContext);

 const messagesEndRef = useRef<HTMLDivElement>(null);

 const [open, setOpen] = useState<boolean>(false);
 const [percent, setPercent] = useState<number>(0);
 const [loading, setLoading] = useState<boolean>(false);
 const [messages, setMessages] = useState<Array<string>>([]);
 const messagesList = useRef<Array<string>>([]);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("command_progress", (value): void => {
    if (value?.completed) {
     handleOpen();
     messagesList.current.push(value?.message);
     setPercent(Number(value?.percentage));
     setTimeout(() => {
      setMessages([...messagesList.current]);
     }, 50);
    }
   });
  }

  return () => {
   !isNil(socket) && socket.off("command_progress");
  };
 }, [socket]);

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  handleReset();
  setOpen(false);
 };
 const handleReset = () => {
  setLoading(true);
  setMessages([]);
  messagesList.current = [];
  setPercent(0);
  setLoading(false);
 };

 const scrollToBottom = () => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 };

 useEffect(() => {
  scrollToBottom();
 }, [messages.length]);

 const DynamicRenderer = ({ text }: { text: string }) => {
  // Split the text and dynamically render React components
  const parsedContent = text
   .split(/(<(?:(?:INFO|ERROR|ACTION|SUCCESS))>.*?<\/(?:(?:INFO|ERROR|ACTION|SUCCESS))>)/g)
   .map((part, index) => {
    if (part.startsWith("<INFO>") && part.endsWith("</INFO>")) {
     const content = part.replace("<INFO>", "").replace("</INFO>", "");
     return (
      <Typography
       color="primary"
       fontFamily="monospace"
       fontWeight="bold"
       key={`command-index-${index}`}>
       {content}
      </Typography>
     );
    } else if (part.startsWith("<ERROR>") && part.endsWith("</ERROR>")) {
     const content = part.replace("<ERROR>", "").replace("</ERROR>", "");
     return (
      <Typography
       color="error"
       fontFamily="monospace"
       fontWeight="bold"
       key={`command-index-${index}`}>
       {content}
      </Typography>
     );
    } else if (part.startsWith("<ACTION>") && part.endsWith("</ACTION>")) {
     const content = part.replace("<ACTION>", "").replace("</ACTION>", "");
     return (
      <Typography
       color="warning"
       fontFamily="monospace"
       fontWeight="bold"
       key={`command-index-${index}`}>
       {content}
      </Typography>
     );
    } else if (part.startsWith("<SUCCESS>") && part.endsWith("</SUCCESS>")) {
     const content = part.replace("<SUCCESS>", "").replace("</SUCCESS>", "");
     return (
      <Typography
       color="success"
       fontFamily="monospace"
       fontWeight="bold"
       key={`command-index-${index}`}>
       {content}
      </Typography>
     );
    }

    return (
     <Typography key={`command-index-${index}`} sx={{ color: "white", fontFamily: "monospace" }}>
      {part}
     </Typography>
    ); // Return plain text parts as-is
   });

  return <>{parsedContent}</>;
 };

 return (
  <Stack>
   <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
    <Stack
     spacing={2}
     sx={{
      backgroundColor: "white",
      flexDirection: "column",
      py: 1,
      px: 2,
      borderRadius: "5px",
      boxShadow: "5px 5px 5px #00000029"
     }}>
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography fontWeight="bold">
       <FormattedMessage id="app.commandList" />
      </Typography>
      <IconButton onClick={handleClose}>
       <IconSelector icon="CloseIcon" />
      </IconButton>
     </Stack>
     {loading ? (
      <CircularProgress />
     ) : (
      <Stack spacing={2}>
       <Stack
        sx={{
         backgroundColor: "black",
         width: "stretch",
         height: "500px",
         overflowY: "scroll",
         wordBreak: "break-word"
        }}>
        {messages.map((element, index) => {
         return (
          <Stack
           direction="row"
           spacing={1}
           key={`render-index-${index}`}
           sx={{
            wordBreak: "break-word",
            whiteSpace: "nowrap"
           }}>
           <DynamicRenderer text={element} />
          </Stack>
         );
        })}
        <Stack direction="row">
         <Typography sx={{ color: "white", fontFamily: "monospace" }}>{">"}</Typography>
         {messages.find((element) => element.includes("[100%]")) ? (
          <Typography sx={{ color: "white", fontFamily: "monospace" }}>{"_ Done"}</Typography>
         ) : (
          <Typography className="cursor4" sx={{ color: "white", fontFamily: "monospace" }}>
           {"_"}
          </Typography>
         )}
        </Stack>
        <div ref={messagesEndRef} />
       </Stack>
       <LinearProgress variant="determinate" value={percent} />
      </Stack>
     )}
    </Stack>
   </Dialog>
  </Stack>
 );
};

export default VncCommandProgress;
