import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { IAllServers } from "types/api/serversApiInterface";
import { serverPermissionsType } from "types/global/user";

type sectionType =
 | "editRebootServer"
 | "showRebuildServer"
 | "editRebuildServer"
 | "showBackupServer"
 | "editBackupServer"
 | "editPowerOffOnServer"
 | "editVncServer"
 | "editIsoServer"
 | "showCredentialsServer"
 | "editCredentialsServer"
 | "showServicesServer"
 | "editServicesServer"
 | "showIpRdnsServer"
 | "editIpRdnsServer"
 | "showServerStatistics"
 | "editRebootAgent";

const ServersSection = ({
 control,
 servers,
 selectedServers,
 serverPermissions,
 setServers,
 addServer,
 setPermission
}: {
 control: any;
 servers: Array<IAllServers>;
 selectedServers: Array<number>;
 serverPermissions: serverPermissionsType;
 setServers: (server: Array<number>) => void;
 addServer: (server: Array<{ id: number; label: string }>) => void;
 setPermission: (section: sectionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();

 return (
  <Accordion>
   <AccordionSummary expandIcon={<ExpandMoreIcon />}>Servers</AccordionSummary>
   <AccordionDetails>
    <Stack spacing={2}>
     <Controller
      name="servers"
      control={control}
      render={({ field }) => (
       <Autocomplete
        {...field}
        multiple
        id="tags-outlined"
        options={servers.map((element) => {
         return {
          id: element.id,
          label: element.server_name
         };
        })}
        filterSelectedOptions
        value={servers
         .filter((element) => selectedServers.includes(element.id))
         .map((element) => {
          return {
           id: element.id,
           label: element.server_name
          };
         })}
        getOptionLabel={(option) => option.label}
        onChange={(e, value, reason) => {
         isNil(value) ? setServers(selectedServers) : addServer(value);
        }}
        renderInput={(params) => (
         <TextField
          {...params}
          label={intl.formatMessage({ id: "teams.usersettings.selectServers" })}
          placeholder="Servers..."
         />
        )}
       />
      )}
     />
     <Stack direction="row">
      <Stack>
       <Controller
        name="editRebootServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.editRebootServer}
            onChange={(e, checked) => {
             setPermission("editRebootServer", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editRebootServer" })}
         />
        )}
       />
       <Controller
        name="editPowerOffOnServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.editPowerOffOnServer}
            onChange={(e, checked) => {
             setPermission("editPowerOffOnServer", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editPowerOffOnServer" })}
         />
        )}
       />
       <Controller
        name="showBackupServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.showBackupServer}
            onChange={(e, checked) => {
             setPermission("showBackupServer", checked);
             if (!checked) setPermission("editBackupServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showBackupServer" })}
         />
        )}
       />
       <Controller
        name="showRebuildServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.showRebuildServer}
            onChange={(e, checked) => {
             setPermission("showRebuildServer", checked);
             if (!checked) setPermission("editRebuildServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showRebuildServer" })}
         />
        )}
       />
       <Controller
        name="showCredentialsServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.showCredentialsServer}
            onChange={(e, checked) => {
             setPermission("showCredentialsServer", checked);
             if (!checked) setPermission("editCredentialsServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showCredentialsServer" })}
         />
        )}
       />
       <Controller
        name="showServicesServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.showServicesServer}
            onChange={(e, checked) => {
             setPermission("showServicesServer", checked);
             if (!checked) setPermission("editServicesServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showServicesServer" })}
         />
        )}
       />
       <Controller
        name="showIpRdnsServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.showIpRdnsServer}
            onChange={(e, checked) => {
             setPermission("showIpRdnsServer", checked);
             if (!checked) setPermission("editIpRdnsServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showIpRdnsServer" })}
         />
        )}
       />
      </Stack>
      <Stack>
       <Controller
        name="editVncServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.editVncServer}
            onChange={(e, checked) => {
             setPermission("editVncServer", checked);
             if (!checked) setPermission("editIpRdnsServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editVncServer" })}
         />
        )}
       />
       <Controller
        name="editIsoServer"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.editIsoServer}
            onChange={(e, checked) => {
             setPermission("editIsoServer", checked);
             if (!checked) setPermission("editIpRdnsServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editIsoServer" })}
         />
        )}
       />
       <Controller
        name="editRebootAgent"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={serverPermissions.editRebootAgent}
            onChange={(e, checked) => {
             setPermission("editRebootAgent", checked);
             if (!checked) setPermission("editIpRdnsServer", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editRebootAgent" })}
         />
        )}
       />
       {serverPermissions.showBackupServer && (
        <Controller
         name="editBackupServer"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={serverPermissions.editBackupServer}
             onChange={(e, checked) => {
              setPermission("editBackupServer", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editBackupServer" })}
          />
         )}
        />
       )}
       {serverPermissions.showRebuildServer && (
        <Controller
         name="editRebuildServer"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={serverPermissions.editRebuildServer}
             onChange={(e, checked) => {
              setPermission("editRebuildServer", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editRebuildServer" })}
          />
         )}
        />
       )}
       {serverPermissions.showCredentialsServer && (
        <Controller
         name="editCredentialsServer"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={serverPermissions.editCredentialsServer}
             onChange={(e, checked) => {
              setPermission("editCredentialsServer", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editCredentialsServer" })}
          />
         )}
        />
       )}
       {serverPermissions.showServicesServer && (
        <Controller
         name="editServicesServer"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={serverPermissions.editServicesServer}
             onChange={(e, checked) => {
              setPermission("editServicesServer", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editServicesServer" })}
          />
         )}
        />
       )}
       {serverPermissions.showIpRdnsServer && (
        <Controller
         name="editIpRdnsServer"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={serverPermissions.editIpRdnsServer}
             onChange={(e, checked) => {
              setPermission("editIpRdnsServer", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editIpRdnsServer" })}
          />
         )}
        />
       )}
      </Stack>
     </Stack>
    </Stack>
   </AccordionDetails>
  </Accordion>
 );
};

export default ServersSection;
