import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useDebounce from "react-use/lib/useDebounce";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { websitesTable } from "constants/tables";

import NoServerFound from "components/shared/emptyList/NoServerFound";
import SortingTable from "components/shared/tables/SortingTable";

import { postSyncronizeSocket } from "redux/handlers/socketHandler";
import { getAllWebsites } from "redux/handlers/websitesHandler";

import { getIspList } from "redux/selectors/ispSelector";
import { getWebsitesList, getWebsitesNumber } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

const WebsitesList = (): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const location = useLocation();
 const { socket } = useContext(AppContext);

 const websitesList = useSelector(getWebsitesList);
 const ispList = useSelector(getIspList);
 const websitesNumberTotal = useSelector(getWebsitesNumber);

 const searchRef = useRef<HTMLInputElement | null>(null);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [selectedIsp, setSelectedIsp] = useState<number>(0);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [reloadData, setReloadData] = useState<boolean>(false);
 const [sitePerformance, setSitePerformance] = useState<
  Array<{ site: string; size: string; logs: string }>
 >([]);

 useEffect(() => {
  (async () => {
   if (location.state && location.state.server) {
    setSelectedIsp(
     ispList.find((element) => element.server_id === location.state.server)?.server_id || 0
    );
   }
   await dispatch(getAllWebsites(currentPage, rowPerPage, selectedIsp, searchRef?.current?.value));
   setIsLoading(false);
   await dispatch(postSyncronizeSocket("site"));
  })();
 }, [selectedIsp, currentPage, rowPerPage, dispatch, searchRef]);

 const parseSizing = (value: number, type: string): string => {
  if (type && type.includes("M")) {
   return `${value} MB`;
  } else if (type && type.includes("G")) {
   return `${value} GB`;
  } else {
   return `${value} KB`;
  }
 };

 useEffect(() => {
  !isNil(socket) &&
   socket.on("app_data", (value): void => {
    if (value?.completed && value?.section === "site") {
     setReloadData(true);
    }
   });
  !isNil(socket) &&
   socket.on("site_logs", async (value): Promise<void> => {
    const logsArr = [];
    for (let element of value) {
     logsArr.push({
      site: element.site,
      size: parseSizing(parseInt(element.website_size), element.website_size),
      logs: element.website_log_size
       ? parseSizing(parseInt(element.website_log_size), element.website_log_size)
       : "not available"
     });
    }
    setSitePerformance(logsArr);
   });
  return () => {
   !isNil(socket) && socket.off("app_data");
   !isNil(socket) && socket.off("site_logs");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getAllWebsites(currentPage, rowPerPage, selectedIsp, searchRef?.current?.value));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllWebsites(currentPage, rowPerPage, selectedIsp, searchRef?.current?.value));
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
 };

 const handleIspChange = (id: number) => {
  setSelectedIsp(id);
  setCurrentPage(0);
 };

 const handleRedirectToMailCreateSite = () => {
  navigate("/app/sites/add");
 };

 const handleRedirectToServerCreate = () => {
  navigate("/servers/add");
 };

 return (
  <Stack mt={5}>
   <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
    <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
     <FormattedMessage id="app.sitesTitle" />
     {` (${websitesNumberTotal})`}
    </Typography>
    {ispList.length > 0 ? (
     <Button
      variant="kxActionButton"
      endIcon={<AddIcon />}
      onClick={handleRedirectToMailCreateSite}>
      <FormattedMessage id="add.site" />
     </Button>
    ) : (
     <Button variant="kxActionButton" endIcon={<AddIcon />} onClick={handleRedirectToServerCreate}>
      <FormattedMessage id="server.action.add" />
     </Button>
    )}
   </Stack>
   {ispList.length > 0 ? (
    <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
     <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row" spacing={2}>
      <TextField
       size="small"
       autoComplete="off"
       inputRef={searchRef}
       label={<FormattedMessage id="search.sites" />}
       InputLabelProps={{ shrink: true }}
       InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
      <Autocomplete
       autoHighlight
       size="small"
       sx={{ mx: 1, width: 240 }}
       onChange={(e, value) => handleIspChange(value && value.id ? value.id : 0)}
       value={
        ispList && selectedIsp !== 0
         ? {
            label: ispList?.find((element) => element.server_id === selectedIsp)?.hostname,
            id: ispList?.find((element) => element.server_id === selectedIsp)?.server_id
           }
         : { label: "", id: 0 }
       }
       options={ispList.map((element) => {
        return {
         label: element.hostname || "",
         id: element.server_id || 0
        };
       })}
       renderInput={(params) => (
        <TextField {...params} label={"Hosts"} InputLabelProps={{ shrink: true }} />
       )}
      />
     </Stack>
     <Divider sx={{ borderColor: "#f86e06" }} />
     <Stack>
      <SortingTable
       tableData={websitesTable}
       maxWidth={650}
       caller="websites"
       listToShow={websitesList}
       sitePerformance={sitePerformance}
      />
      <TablePagination
       component="div"
       count={websitesNumberTotal}
       page={currentPage}
       rowsPerPage={rowPerPage}
       onPageChange={handleOnPageChange}
       onRowsPerPageChange={handleOnRowsPerPageChange}
      />
     </Stack>
    </Paper>
   ) : (
    <NoServerFound type="webserver" />
   )}
  </Stack>
 );
};

export default WebsitesList;
